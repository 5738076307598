import React, {useEffect, useRef, useState} from 'react';
import './LanguageSwitch.scss';
import Kg from './flag_kg.png';
import Ru from './flag_ru.jpg';
import Eng from './flag_eng.png';
import i18n from "i18next";

const LanguageSwitch = ({light}) => {

    const [switchState, setSwitchState] = useState(false);
    const myBlockRef = useRef(null);
    const PageTitle = document.querySelector('#PageTitle')

    const getDefaultLanguage = () => {
        switch (localStorage.getItem('language')) {
            case 'ru':
                return Ru
            case 'eng':
                return Eng
            case 'kg':
                return Kg
            default:
                return Ru
        }
    }

    const changePageTitle = () => {
        if (localStorage.getItem('language') === 'kg') {
            PageTitle.textContent = 'Qoom Us - АКШдагы биздин мекендештер үчүн биринчи маркетплейс'
        } else if (localStorage.getItem('language') === 'eng') {
            PageTitle.textContent = 'Qoom Us - is our first marketplace for search and placement of ads in the USA!'
        } else {
            PageTitle.textContent = 'Qoom Us - Первый маркетплейс наших для поиска и размещения объявлений в США!'
        }
    }

    const changeLanguage = (lang) => {
        if (localStorage.getItem('language') !== lang) {
            setSwitchState(false)
            localStorage.setItem('language', lang);
            i18n.changeLanguage(lang);
            changePageTitle()
        }
    }

    useEffect(() => {
        changePageTitle()
        function handleClickOutside(event) {
            if (myBlockRef.current && !myBlockRef.current.contains(event.target)) {
                setSwitchState(false);
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [])

    return (
        <div ref={myBlockRef}
             className={`LanguageSwitch ${light && 'LanguageSwitch__light'} ${switchState && 'LanguageSwitch__closed'}`}>

            <div onClick={() => setSwitchState(true)} className="LanguageSwitch__defaultValue">
                <img src={getDefaultLanguage()} alt=""/>
            </div>

            <ul style={{display: `${switchState ? 'flex' : 'none'}`}} className="LanguageSwitch__select">
                <li onClick={() => changeLanguage('ru')} className="LanguageSwitch__item">
                    <img src={Ru} alt=""/>
                </li>
                <li onClick={() => changeLanguage('kg')} className="LanguageSwitch__item">
                    <img src={Kg} alt=""/>
                </li>
                <li onClick={() => changeLanguage('eng')} className="LanguageSwitch__item">
                    <img src={Eng} alt=""/>
                </li>
            </ul>

        </div>
    );
};

export default LanguageSwitch;