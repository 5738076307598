import i18n from "i18next";
import {initReactI18next} from "react-i18next";

//main locales
import engMain from "./eng/eng.json";
import ruMain from "./ru/ru.json";
import kgMain from "./kg/kg.json";



i18n
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: {
                    main: ruMain,
                    // actions: ruActions,
                }
            },
            kg: {
                translation: {
                    main: kgMain,
                    // actions: kgActions,
                }
            },
            eng: {
                translation: {
                    main: engMain,
                    // actions: engActions
                }
            }
        },
        lng: localStorage.getItem('language') || 'ru',
        fallbackLng: localStorage.getItem('language') || 'ru',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ["localStorage", "cookie"],
            caches: ["localStorage", "cookie"],
        }
    });

export default i18n;