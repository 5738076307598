import React, {useEffect, useState} from 'react';
import './ModalCitiesWindow.scss';
import {useRecoilState} from "recoil";
import {modalWindowCities} from "../../store/modalStates";
import {allCitiesState, userCurrentCity} from "../../store/userDataStates";
import Button from "../../components/Button/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import {useTranslation} from "react-i18next";

const ModalCitiesWindow = () => {

    const [modalCities, setModalCities] = useRecoilState(modalWindowCities);
    const [currentCity, setCurrentCity] = useRecoilState(userCurrentCity);
    const [filterInput, setFilterInput] = useState('');
    const [allCities] = useRecoilState(allCitiesState)
    const {t} = useTranslation();
    const changeCurrentCity = (city) => {
        setCurrentCity(city);
        localStorage.setItem('location', JSON.stringify
        (city));
        setModalCities(false)
    }

    const citiesFilter = (arr) => {
        return arr.filter((item) => `${item?.city + ' ' + item?.stateName}`.toLowerCase().includes(filterInput.toLowerCase())).sort((a, b) => a.city > b.city ? 1 : -1)
    }

    useEffect(() => {
        setFilterInput('')
        let body = document.querySelector('body')
        if (modalCities) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = 'auto'
        }
    }, [modalCities])

    return (
        <>
            {modalCities && <div onClick={() => setModalCities(false)} className="modalWindowCities__blur"></div>}
            <div className={`modalWindowCities ${modalCities && 'modalWindowCities__active'}`}>

                <CustomInput value={filterInput} onChange={(e) => {
                    setFilterInput(e.target.value)
                }} disableButton={true} width={'100%'} placeholder={t("main.actions.find")}/>

                <div className="modalWindowCities__citiesContainer">
                    {
                        allCities?.grouped ? allCities?.grouped.map((state) => (
                                citiesFilter(state?.options).length ?
                                    <div key={state?.stateName} className="ModalWindow__state">
                                        <h2 className="bodyL body500">{state?.stateName}</h2>
                                        {
                                            citiesFilter(state?.options).map((city) => (
                                                <Button active={currentCity?.id === city?.id} key={city?.id}
                                                        onClick={() => changeCurrentCity(city)}
                                                        title={`${city?.city}, ${city?.stateId}`}></Button>
                                            ))

                                        }
                                    </div>
                                    : ''
                            ))
                            : ''
                    }
                </div>
            </div>
        </>
    );
};

export default ModalCitiesWindow;