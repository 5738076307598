import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import './auth.scss'
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import ForgotPassword from "./ForgotPassword";

const AuthPage = () => {

    const [profile] = useRecoilState(currentProfile);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [])

    if (profile) {
        return <Navigate to={'/'}/>
    }

    return (
        <main className="AuthPage">
            <div className="container">
                <div className="AuthPage__content">

                    <ButtonBack customAction={()=> navigate('/')}/>

                    <Routes>
                        <Route path={'/login'} element={<Login/>}/>
                        <Route path={'/register'} element={<Register/>}/>
                        <Route path={'/login/forgotPassword'} element={<ForgotPassword/>}/>
                    </Routes>

                </div>
            </div>
        </main>
    );
};

export default AuthPage;