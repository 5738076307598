import React from 'react';
import PromotionType from "../../PromotionType/PromotionType";
import './ApartCarCard.scss'
import LocationIcon from "../../../assets/icons/locationIcon";
import {useTranslation} from "react-i18next";
import {titleSlicer} from "../../../hooks/titleSlicer";
import {getADate, getPaymentType, navigateToPost} from "../../../hooks/mainFunctions";
import GetLocationByIdSpan from "../../GetLocationByIdSpan/GetLocationByIdSpan";
import StatusComponent from "../../StatusComponent/StatusComponent";
import {useNavigate} from "react-router-dom";
import ViewsCount from "../../ViewsCount/ViewsCount";

const ApartCarCard = ({cardData}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div onClick={() => navigateToPost(cardData, navigate)}
             className={`PostCard PostCard__ApartCarCard`}>

            <div className="PostCard__ApartCarCard_top">


                {
                    cardData?.status !== "UNDER_REVIEW" ?
                        <PromotionType contained={true} type={cardData?.promotionType?.name}/>
                        : <StatusComponent status={cardData?.status}/>
                }

                <img
                    src={(cardData?.images && cardData?.images[0]) || "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"}
                    alt=""/>
            </div>

            <div className="PostCard__ApartCarCard_padding">

                <div className="PostCard__ApartCarCard_mid">
                    <span className="bodyL titleCard">{titleSlicer(cardData?.title)}</span>
                    <span className="bodyM time">{getADate(cardData)}</span>
                </div>

                {
                    cardData?.price &&
                    <h3 className="bodyXL body500">${cardData?.price} {getPaymentType(t, cardData)}</h3>
                }

                <div className="PostCard__ApartCarCard_bot">

                    <div className="bodyM body400 PostCard__ApartCarCard_bot-location">
                        <LocationIcon color={'var(--primary-color)'}/>
                        <GetLocationByIdSpan cardData={cardData}/>
                    </div>

                    <ViewsCount viewsCount={cardData?.views}/>

                </div>

            </div>

        </div>
    );
};

export default ApartCarCard;