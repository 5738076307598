import axios from "axios";

const prodApi = 'https://api.qoomus.net/api/v1';
// const prodApi2 = 'https://3.149.26.225:8080/api/v1';
// const devApi = 'https://dev.qoomus.net/api/v1'

export const instance = axios.create({
    baseURL: prodApi,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
});


export const updateInstanceConfig = () => {
    if (localStorage.getItem('accessToken')) {
        instance.interceptors.request.use(config => {
            const updatedToken = localStorage.getItem('accessToken');
            config.headers.Authorization = `Bearer ${updatedToken}`;
            return config;
        }, error => {
            return Promise.reject(error);
        });
    };
};