import React from 'react';
import './ProfileLogo.scss';
import GuestIcon from '../../assets/icons/unnamed.jpg';

const ProfileLogo = ({logo, onClick}) => {
    return (
        <div onClick={onClick} className="ProfileLogo">
            <img src={logo || GuestIcon} alt=""/>
        </div>
    );
};

export default ProfileLogo;