import React from 'react';
import './FullScreenLoader.scss';

const FullScreenLoader = () => {
    return (
        <div className="FullScreenLoader">
            <span className="loader">
                <div></div>
            </span>
        </div>
    );
};

export default FullScreenLoader;