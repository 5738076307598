import {atom} from "recoil";

export const categoriesAllList = atom({
    key: 'allCategories',
    default: [],
});

export const paymentsAllList = atom({
    key: 'allPaymentStates',
    default: []
})