import React from 'react';

const AddPostButton = () => {
    return (
        <svg className="AddPostButton" width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="64" height="64" rx="32" fill="var(--primary-color)"/>
            <path d="M42.502 32.1085L22.9069 32.1084" stroke="#FDE6FF" strokeWidth="2" strokeMiterlimit="10"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.8043 41.8097L32.8042 22.2146" stroke="#FDE6FF" strokeWidth="2" strokeMiterlimit="10"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default AddPostButton;