import React, {useEffect, useState} from 'react';
import './ProfileSettings.scss';
import {useRecoilState, useSetRecoilState} from "recoil";
import {currentProfile} from "../../../store/userDataStates";
import {useNavigate} from "react-router-dom";
import ProfileLogo from "../../../components/ProfileLogo/ProfileLogo";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import CustomInput from "../../../components/CustomInput/CustomInput";
import ChangePicture from '../../../assets/icons/pictureChange.svg';
import Button from "../../../components/Button/Button";
import {toast} from "react-toastify";
import {changeProfileData} from "../../../api/authApi";
import {deleteFile, handleCompareImage, uploadFile} from "../../../api/fileApi";
import Telegram from '../../../assets/icons/Telegram.svg';
import Whats from '../../../assets/icons/WhatsApp.svg';
import Phone from '../../../assets/icons/phone.svg';
import {fullScreenLoaderState} from "../../../store/modalStates";
import {updateInstanceConfig} from "../../../api/instance";
import {useTranslation} from "react-i18next";

const ProfileSettings = () => {

    const [profile, setProfile] = useRecoilState(currentProfile);
    const [newProfileData, setNewProfileData] = useState(profile);
    const [newLogo, setNewLogo] = useState('');
    const navigate = useNavigate();
    const setLoader = useSetRecoilState(fullScreenLoaderState)
    const {t} = useTranslation();
    const [showFill, setShowFill] = useState(false)
    const logOutFromAccount = () => {
        setProfile(null);
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
        toast('Сеанс завершен!', {type: 'warning', autoClose: 3000});
        updateInstanceConfig();
        window.location.reload()
    }

    const changeProfileFunc = (val, key) => {
        setNewProfileData({
            ...newProfileData,
            [key]: val
        })
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setLoader(true);
        if (newLogo) {
            if (profile?.avatar?.path) {
                deleteFile(profile?.avatar?.path)
            }
            handleCompareImage(newLogo).then((res) => {
                uploadFile(res).then((res) => {
                    changeProfileData({
                        ...newProfileData,
                        avatar: {
                            path: res?.data[0]
                        }
                    }, setProfile)
                    setLoader(false)
                }).catch(() => {
                    setLoader(false)
                })
            }).catch(() => {
                setLoader(false)
            })
        } else {
            changeProfileData(newProfileData, setProfile).then(() => {
                setLoader(false)
            })
        }
        navigate('/profile')
    }

    const phoneValiator = (e) => {
        let phoneNumber = e.target.value.replace(/\D/g, '');
        if (phoneNumber.length >= 3 && phoneNumber.length <= 6) {
            phoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3)}`;
        } else if (phoneNumber.length > 6) {
            phoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;
        }
        e.target.value = phoneNumber;

        const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValid = phoneNumberRegex.test(phoneNumber);
        if (isValid) {
            e.target.parentNode.classList.remove('InvalidInput')
        } else {
            e.target.parentNode.classList.add('InvalidInput');
        }
    }

    const telegramValidator = (e) => {
        const telegramLinkRegex = /^https:\/\/t\.me\//;
        const url = e?.target?.value;
        const isValid = telegramLinkRegex.test(url);

        if (isValid) {
            e.target.parentNode.classList.remove('InvalidInput')
        } else {
            e.target.parentNode.classList.add('InvalidInput');
        }
    }

    const fillSomeContacts = () => {
        if (!newProfileData?.telegram && !newProfileData?.phoneNumber && !newProfileData?.whatsapp) {
            if (!showFill) {
                setShowFill(true);
            }
            return true
        } else {
            if (showFill) {
                setShowFill(false)
            }
            return false
        }
    }

    useEffect(() => {
        if (showFill) {
            toast(t("main.Warnings.fillSomeInput"), {type: 'info', position: 'top-center', autoClose: 3000})
        }
    }, [showFill])

    return (
        <main className="ProfileSettings">
            <div className="container">
                <div className="ProfileSettings__content">
                    <ButtonBack/>
                    <form onSubmit={submitHandler} className="ProfileSettings__content_form">
                        <div className="ProfileSettings__content_form-logo">
                            <ProfileLogo logo={(newLogo && URL.createObjectURL(newLogo)) || profile?.avatar?.path}/>
                            <label>
                                <input onChange={(e) => setNewLogo(e.target.files[0] || '')} type="file"
                                       accept={'image/*'}/>
                                <img src={ChangePicture} alt=""/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <span className="bodyL">{t("main.Profile.name")}</span>
                                <CustomInput required onChange={(e) => changeProfileFunc(e.target.value, 'firstName')}
                                             width={'100%'} value={newProfileData?.firstName} disableButton/>
                            </label>

                            <label>
                                <span className="bodyL">{t("main.Profile.lastName")}</span>
                                <CustomInput required onChange={(e) => changeProfileFunc(e.target.value, 'lastName')}
                                             width={'100%'} value={newProfileData?.lastName} disableButton/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <span className="bodyL">{t("main.Profile.companyName")}</span>
                                <CustomInput onChange={(e) => changeProfileFunc(e.target.value, 'companyName')}
                                             width={'100%'} value={newProfileData?.companyName} disableButton/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <span className="bodyL">{t("main.Profile.contactData")}</span>
                                <CustomInput
                                    required={fillSomeContacts()}
                                    validator={phoneValiator} logoLeft={Phone}
                                    onChange={(e) => changeProfileFunc(e.target.value, 'phoneNumber')}
                                    placeholder={'(XXX) XXX-XXXX'} width={'100%'}
                                    value={newProfileData?.phoneNumber}
                                    disableButton/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <CustomInput
                                    required={fillSomeContacts()}
                                    validator={telegramValidator} logoLeft={Telegram}
                                    onChange={(e) => changeProfileFunc(e.target.value, 'telegram')}
                                    placeholder={'https://t.me/example'} width={'100%'}
                                    value={newProfileData?.telegram}
                                    disableButton/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <CustomInput
                                    required={fillSomeContacts()}
                                    logoLeft={Whats}
                                    onChange={(e) => changeProfileFunc(e.target.value, 'whatsapp')}
                                    placeholder={'Whatsapp'} width={'100%'} value={newProfileData?.whatsapp}
                                    disableButton/>
                            </label>
                        </div>

                        <div className="ProfileSettings__content_form-label">
                            <label>
                                <span className="bodyL">{t("main.Profile.email")}</span>
                                <CustomInput disabled width={'100%'} value={newProfileData?.email} disableButton/>
                            </label>
                        </div>


                        <Button clx={'SaveButton'} full={true} title={t("main.actions.saveData")}/>
                        <Button clx={'ButtonLogout'} onClick={logOutFromAccount} full={true}
                                title={t("main.actions.logout")}/>
                    </form>
                </div>
            </div>
        </main>
    );
};

export default ProfileSettings;