import {instance} from "./instance";

let headerConfig = {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
};


export const getCategory = () => {
    return instance.get('/category', {
        headers: null
    }).then((res) => {
        return res
    })
}

export const getPaymentTypes = () => {
    return instance.get('/price-type', {
        headers: null
    }).then((res) => {
        return res
    })
}

export const getWorkTypes = () => {
    return instance.get('/enums/work_types', {
        headers: null
    }).then((res) => {
        return res
    })
}

export const getPromotionTypes = () => {
    return instance.get('/promotion-type').then((res) => {
        return res
    })
}

export const postAdvertisementApi = (postData) => {
    const newData = {
        ...postData,
        categoryId: postData?.categoryId?.id,
        locationId: postData?.locationId?.id,
        priceTypeId: postData?.priceTypeId?.id,
        promotionTypeId: postData?.promotionTypeId?.id
    }
    return instance.post('/adv', newData).then((res) => {
        return res
    })
}

export const getAdvertisementApi = (page, size, location, category, search, admin) => {
    let url = `/adv/filter-pageable?status=APPROVED&categoryId=${category?.id}&page=${page}&size=${size}&sort=createdDate,DESC${search ? `&title=${search}` : ''}`;
    if (admin) {
        url = url.replace('APPROVED', 'UNDER_REVIEW')
    }
    if (location && category?.categoryName !== 'questions') {
        url += `&locationId=${location}`
    }
    return instance.get(url, admin ? headerConfig : {headers: null}).then((res) => {
        return res
    })
}

export const getAdvertisementById = (id, admin) => {
    return instance.get(`adv/${id}`, admin ? headerConfig : {headers: null}).then((res) => {
        return res
    })
}

export const getUserAdvertisementById = (id) => {
    return instance.get(`adv/get-by-user/${id}`).then((res) => {
        return res
    })
}

export const getAdvByUser = () => {
    return instance.get(`/adv/get-by-user?status=APPROVED&sort=createdDate,DESC&page=0&size=99999`,).then((res) => {
        return res?.data
    })
}

export const deleteUserPost = (id) => {
    return instance.delete(`/adv/${id}`).then((res) => {
        return res
    })
}

export const archivePostInstance = (postData) => {
    return instance.put(`/adv/${postData?.id}`, {
        ...postData,
        status: postData?.status === 'APPROVED' ? 'DEACTIVATED' : 'APPROVED'
    })
}