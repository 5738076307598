import React from 'react';
import './HeaderLogin.scss';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {currentProfile, userCurrentCity} from "../../../store/userDataStates";
import Button from "../../../components/Button/Button";
import {useTranslation} from "react-i18next";
import LocationIcon from "../../../assets/icons/locationIcon";
import {modalWindowCities} from "../../../store/modalStates";
import {NavLink} from "react-router-dom";
import LanguageSwitch from "../../../components/LanguageSwitch/LanguageSwitch";
import {useMediaQuery} from "react-responsive";
import ProfileLogo from "../../../components/ProfileLogo/ProfileLogo";
import {titleSlicer} from "../../../hooks/titleSlicer";

const HeaderLogin = () => {

    const {t} = useTranslation();
    const [userProfile] = useRecoilState(currentProfile)
    const locationCurrent = useRecoilValue(userCurrentCity);
    const setModalCities = useSetRecoilState(modalWindowCities);
    const isMobile = useMediaQuery({maxWidth: 768});

    return (
        <div className="LoginActions">


            {
                locationCurrent ? <span onClick={() => setModalCities(prev => !prev)}
                                        className="header__nav_location bodyM">
                    <LocationIcon color={'var(--primary-color)'}/>
                    {titleSlicer(locationCurrent?.city, 8)}, {locationCurrent?.stateId}
                </span> : ''
            }

            <LanguageSwitch/>


            {
                userProfile ?
                    <NavLink to={'/profile'}>
                        <ProfileLogo logo={userProfile?.avatar?.path}/>
                    </NavLink>
                    : <>
                        <NavLink to={'/auth/login'}>
                            <Button type={'primary__button'} title={t('main.navigation.login')}/>
                        </NavLink>
                        {
                            !isMobile &&   <NavLink to={'/auth/register'}>
                                <Button title={t('main.navigation.register')}/>
                            </NavLink>
                        }
                    </>
            }


        </div>
    );
};

export default HeaderLogin;