import React from 'react';
import Image from "../../assets/images/imageUpload.svg";
import './ChooseFilesInput.scss'
import {uploadFilesForm} from "../../hooks/mainFunctions";
import {useSetRecoilState} from "recoil";
import {fullScreenLoaderState} from "../../store/modalStates";
import {useTranslation} from "react-i18next";

const ChooseFilesInput = ({postData, changePostKeyAndValue}) => {

    const {t} = useTranslation();
    const setLoader = useSetRecoilState(fullScreenLoaderState);
    const uploadFiles = async (e) => {
        await uploadFilesForm(setLoader, changePostKeyAndValue, e)
    }

    const changePicturesPosition = (picture) => {
        const sortedArray = postData?.images?.sort((file) => file?.name === picture?.name ? -1 : 1)
        changePostKeyAndValue(sortedArray, 'images')
    }

    const removePicture = (picture) => {
        const sortedArray = postData?.images?.filter((file) => file?.name !== picture?.name)
        changePostKeyAndValue(sortedArray, 'images')
    }

    return (
        <label className="uploadFiles">
            <input disabled={postData?.images?.length} required max={10}
                   maxLength={10} onChange={uploadFiles}
                   type="file"
                   multiple
                   accept={'image/*'}/>
            <span>{t("main.Form.images")}</span>
            <div className="row__images">
                {
                    postData?.images?.length ? postData?.images?.map((file, idx) => (
                            <div key={idx} className={`uploadFiles__imageBlock ${idx === 0 ? 'FirstImage' : 'secondaryImage'}`}>

                                <div onClick={() => removePicture(file)} className="deletePicture">
                                    <span className="bodyM">{t("main.actions.delete")}</span>
                                </div>


                                <img key={idx}
                                     src={URL.createObjectURL(postData?.images[idx])}
                                     alt=""/>

                                {
                                    idx !== 0 ? <div onClick={() => changePicturesPosition(file)} className="chooseMain">
                                        <span className="bodyM">{t("main.actions.makeMain")}</span>
                                    </div> : ''
                                }
                            </div>
                        ))
                        : <img className={'FirstImage'}
                               src={Image}
                               alt=""/>
                }

            </div>

        </label>
    );
};

export default ChooseFilesInput;