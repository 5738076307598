import React from 'react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import Select from "react-select";
import ChooseLocationInput, {themeSelectColor} from "../../../components/ChooseLocationInput/ChooseLocationInput";
import {useTranslation} from "react-i18next";
import ChooseFilesInput from "../../../components/ChooseFilesInput/ChooseFilesInput";

const CreateApartment = ({paymentType, postData, changePostKeyAndValue}) => {

    const {t} = useTranslation();

    return (
        <form className="CreatePost__content_form">

            <div className="row">
                <ChooseFilesInput changePostKeyAndValue={changePostKeyAndValue} postData={postData}/>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.title")}</span>
                    <CustomInput onChange={(e) => changePostKeyAndValue(e.target.value, 'title')}
                                 value={postData?.title}
                                 required placeholder={t("main.Form.title")} width={'100%'} disableButton/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.cost")}</span>
                    <CustomInput onChange={(e) => changePostKeyAndValue(e.target.value, 'price')}
                                 value={postData?.price} inputType={'number'} required placeholder={'$'} width={'100%'}
                                 disableButton/>
                </label>
                <label>
                    <span>{t("main.Form.typeCost")}</span>
                    <Select required theme={themeSelectColor}
                            value={postData?.priceTypeId}
                            defaultValue={postData?.priceTypeId}
                            onChange={(e) => changePostKeyAndValue(e, 'priceTypeId')}
                            formatOptionLabel={(e) => t(`main.paymentTypes.${e?.name}`)}
                            getOptionValue={(e) => e?.id}
                            options={paymentType}
                    />
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.city")}</span>
                    <ChooseLocationInput postData={postData} required changeValue={changePostKeyAndValue}/>
                </label>
            </div>


            <div className="row">
                <label>
                    <span>{t("main.Form.description")}</span>
                    <CustomInput inputType={'textArea'}
                                 onChange={(e) => changePostKeyAndValue(e?.target.value, 'description')}
                                 value={postData?.description} required placeholder={t("main.Form.description")} width={'100%'}
                                 disableButton/>
                </label>
            </div>

        </form>
    );
};

export default CreateApartment;