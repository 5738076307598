import React from 'react';
import './Features.scss';
import {useTranslation} from "react-i18next";
import Opportunities from './opportunities.svg';
import Business from './business.svg';

const Features = () => {

    const {t} = useTranslation();

    return (
        <div className="Features">
            <div className="container">
                <div className="Features__content">

                    <div className="Features__content_block">
                        <div className="Features__content_block-info">
                            <h2 className="header2">{t("main.FeaturesPage.title1")}</h2>
                            <span className="bodyXL">{t("main.FeaturesPage.subtitle")}</span>
                        </div>

                        <img className="opportunities" src={Opportunities} alt=""/>

                    </div>

                    <div className="Features__content_block Features__content_block2">
                        <img className="business" src={Business} alt=""/>

                        <div className="Features__content_block-info Features__content_block-info2">
                            <h2 className="header2">{t("main.FeaturesPage.title2")}</h2>
                            <span className="bodyXL">{t("main.FeaturesPage.subtitle2")}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Features;