import React from 'react';
import './EmptyContent.scss';
import AddPostButton from "../../assets/icons/AddPostButton";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const EmptyContent = () => {
    const {t} = useTranslation();
    return (
        <div className="EmptyContent">
            <h2 className='bodyXL'>{t("main.Warnings.noAdds")}</h2>
            <NavLink to={'/createPost'}>
                <AddPostButton/>
            </NavLink>
        </div>
    );
};

export default EmptyContent;