import React from 'react';
import './main.scss';
import {useTranslation} from "react-i18next";
import AboutPlatform from "./sections/AboutPlatform/AboutPlatform";
import CustomInput from "../../components/CustomInput/CustomInput";
import PostSomething from "./sections/PostSomething/PostSomething";
import Features from "./sections/Features/Features";
import {useRecoilState, useSetRecoilState} from "recoil";
import {chooseCategoryState, searchInputState} from "../../store/searchState";

const Main = () => {

    const {t} = useTranslation();
    const [searchState, setSearchState] = useRecoilState(searchInputState);
    const setCategory = useSetRecoilState(chooseCategoryState)

    const submitSearch = (e) => {
        setCategory(true)
    }

    return (
        <main className="Main">
            <div className="container">
                <section className="Main__content">

                    <div className="Main__content_left">

                        <h1 className="header2 mainTitle">
                            {t("main.main.mainTitle")}
                        </h1>

                        <CustomInput value={searchState} clx={'MainSearchForm'} onChange={(e) => setSearchState(e.target.value)}
                                     onSearch={(e) => submitSearch(e)} width={'100%'} placeholder={`${t('main.actions.search')}...`}/>

                    </div>

                </section>
            </div>

            <AboutPlatform/>
            <PostSomething/>
            <Features/>
        </main>
    );
};

export default Main;