import React from 'react';
import './Header.scss';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import HeaderLogin from "./HeaderLogin/HeaderLogin";
import {useMediaQuery} from 'react-responsive';
import LogoType from "../../components/Logotype/LogoType";
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";

const Header = () => {

    const {t} = useTranslation();
    const isMobile = useMediaQuery({maxWidth: 560});
    const [profile] = useRecoilState(currentProfile);

    if (window.location.href.includes('about')) {
        return <></>
    }


    return (
        <header className={`header`}>
            <div className="container">
                <nav className="header__nav">

                    <NavLink to={'/'}>
                        <LogoType/>
                    </NavLink>

                    {
                        !isMobile && <>
                            <NavLink className={"bodyM"} to={'/about'}>{t('main.navigation.about')}</NavLink>
                            <NavLink className={"bodyM"} to="/marketplace">{t('main.navigation.section')}</NavLink>
                            {
                                profile?.role?.name === 'ROLE_ADMIN' ?
                                    <NavLink className={"bodyM"} to="/admin">Admin</NavLink> : ''
                            }
                        </>
                    }

                </nav>

                <HeaderLogin/>

            </div>
        </header>
    );
};

export default Header;