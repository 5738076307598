import React from 'react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import ChooseLocationInput from "../../../components/ChooseLocationInput/ChooseLocationInput";
import ChooseFilesInput from "../../../components/ChooseFilesInput/ChooseFilesInput";
import {useTranslation} from "react-i18next";

const CreateBusiness = ({postData, changePostKeyAndValue}) => {

    const {t} = useTranslation()

    return (
        <form className="CreatePost__content_form">

            <div className="row">
                <ChooseFilesInput changePostKeyAndValue={changePostKeyAndValue} postData={postData}/>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.title")}</span>
                    <CustomInput onChange={(e) => changePostKeyAndValue(e.target.value, 'title')}
                                 value={postData?.title}
                                 required placeholder={t("main.Form.title")} width={'100%'} disableButton/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.city")}</span>
                    <ChooseLocationInput postData={postData} required changeValue={changePostKeyAndValue}/>
                </label>
            </div>


            <div className="row">
                <label>
                    <span>{t("main.Form.description")}</span>
                    <CustomInput inputType={'textArea'}
                                 onChange={(e) => changePostKeyAndValue(e?.target.value, 'description')}
                                 value={postData?.description} required placeholder={t("main.Form.description")} width={'100%'}
                                 disableButton/>
                </label>
            </div>

        </form>
    );
};

export default CreateBusiness;