import {atom} from 'recoil';

export const userCurrentCity = atom({
    key: 'userCurrentCity',
    default: null,
});

export const currentProfile = atom({
    key: 'userProfile',
    default: null,
});

export const allCitiesState = atom({
    key: 'allCitiesList',
    default: {}
})