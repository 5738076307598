import React, {useEffect, useState} from 'react';
import './PostDetails.scss';
import {useParams} from "react-router-dom";
import PostCard from "../../components/PostCard/PostCard";
import PromotionType from "../../components/PromotionType/PromotionType";
import LocationIcon from "../../assets/icons/locationIcon";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import GetLocationByIdSpan from "../../components/GetLocationByIdSpan/GetLocationByIdSpan";
import {
    archivePostInstance,
    deleteUserPost,
    getAdvertisementApi,
    getAdvertisementById,
    getUserAdvertisementById
} from "../../api/postApi";
import {
    getADate,
    getClassCards,
    getPaymentType,
    getTextWithParagraphs,
    returnHeightProfile
} from "../../hooks/mainFunctions";
import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import NoContent from "../../components/NoContent/NoContent";
import Button from "../../components/Button/Button";
import Phone from "../../assets/icons/phone.svg";
import Whats from "../../assets/icons/WhatsApp.svg";
import Telegram from "../../assets/icons/Telegram.svg";
import ProfileLogo from "../../components/ProfileLogo/ProfileLogo";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import AdminButtons from "../../components/AdminButtons/AdminButtons";
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import ViewsCount from "../../components/ViewsCount/ViewsCount";
import StatusComponent from "../../components/StatusComponent/StatusComponent";
import TrashIcon from "../../assets/icons/trashIcon";
import {toast} from "react-toastify";
import ConfirmModal from "../../shared/ConfirmModal/ConfirmModal";
import ArchiveIcon from "../../assets/icons/ArchiveIcon";


const PostDetails = ({user}) => {

    const params = useParams();
    const [postData, setPostData] = useState(null);
    const [samePosts, setSamePosts] = useState();
    const {t} = useTranslation();
    const [localLoader, setLocalLoader] = useState(false);
    const [showContacts, setShowContacts] = useState();
    const [profile] = useRecoilState(currentProfile);
    const [additionalInfo, setAdditionalInfo] = useState({});
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmArchive, setConfirmArchive] = useState(false);

    useEffect(() => {
        setLocalLoader(true)
        if (user) {
            getUserAdvertisementById(params?.id).then((res) => {
                setPostData(res?.data)
            }).finally((err) => {
                setLocalLoader(false)
            })
        } else {
            getAdvertisementById(params?.id, profile?.role?.name === 'ROLE_ADMIN').then((res) => {
                setPostData(res?.data)
                getAdvertisementApi(0, 4, null, res?.data?.category).then((res2) => {
                    setSamePosts(res2?.data?.content?.filter((el) => el?.id !== res?.data?.id));
                    window.scrollTo({
                        top: 0
                    })
                    setLocalLoader(false)
                }).catch((err) => {
                    setLocalLoader(false)
                })
            }).catch((err) => {
                setLocalLoader(false)
            })
        }
    }, [window.location.href]);

    useEffect(() => {
        const body = document.querySelector('body');
        const modalWindow = document.querySelector('.modalWindowCities ');
        if (postData?.category?.categoryName === 'sxodim') {
            body.style.background = '#f1f1f1'
            body.style.setProperty('--grey-white-color', '#FFF');
            body.style.setProperty('--primary-color', 'rgb(233, 62, 44)');
            modalWindow.style.background = '#f1f1f1'
        }
        if (postData?.otherInfo) {
            setAdditionalInfo(JSON.parse(postData?.otherInfo))
        }
        return () => {
            body.style.background = '#FFF'
            modalWindow.style.background = 'var(--secondary-color)'
            body.style.setProperty('--primary-color', '#11A27F');
            body.style.setProperty('--grey-white-color', '#F5F7FC');
        }
    }, [postData])

    const deletePost = () => {
        setLocalLoader(true)
        deleteUserPost(postData?.id).then((res) => {
            toast(t("main.Warnings.deleted"), {type: 'success', autoClose: 3000})
            setLocalLoader(false)
            window.history.back()
        }).catch(() => {
            setLocalLoader(false)
            toast('Error', {type: 'error', autoClose: 3000})
        });
        document.querySelector('body').style.overflow = 'auto'
    }

    const archivePost = () => {
        setConfirmArchive(false)
        setLocalLoader(true)
        archivePostInstance(postData).then((res) => {
            setPostData(res?.data)
        }).finally(() => {
            setLocalLoader(false)
        })
    }

    const getTypeOfCards = (type) => {
        if (type === 'class') {
            return getClassCards(postData?.category?.categoryName)
        }
    }

    return (
        <main className="PostDetails">

            <ConfirmModal action={deletePost}
                          title={t("main.Warnings.deleteConfirm")}
                          confirmModal={confirmDelete} setConfirmModal={setConfirmDelete}/>

            <ConfirmModal action={archivePost}
                          title={postData?.status === 'APPROVED' ? t("main.Warnings.archiveConfirm") : t("main.Warnings.returnConfirm")}
                          confirmModal={confirmArchive} setConfirmModal={setConfirmArchive}/>

            <div className="container">
                {
                    postData ? <div className="PostDetails__content">

                            <ButtonBack/>

                            <div className="PostDetails__content_post">
                                {
                                    postData?.images?.length > 0 && postData?.images[0]
                                        ?
                                        postData?.images?.length === 1 ? <div className="PostDetails__content_post-left">
                                                <img className="halfWidth" src={postData?.images[0]} alt=""/>
                                            </div>
                                            : <div className="PostDetails__content_post-left">
                                                <ImageGallery additionalClass={'halfWidth'} originalWidth={'50%'}
                                                              items={postData?.images?.map((image) => ({
                                                                  original: image,
                                                                  thumbnail: image,
                                                              }))}/>
                                            </div>
                                        : ''
                                }

                                <div className="PostDetails__content_post-right">
                                    <PromotionType type={postData?.promotionType?.name}/>

                                    {
                                        user ? <div className="PostDetails__content_post-right-userInterface">
                                                <StatusComponent status={postData?.status}/>

                                                <div className="buttons">
                                                    {/*<Button title={'Edit'} iconRight={<EditIcon/>}/>*/}
                                                    <Button onClick={() => setConfirmDelete(true)} title={'Delete'}
                                                            iconRight={<TrashIcon/>}/>

                                                    {
                                                        postData?.status === 'APPROVED' || postData?.status === 'DEACTIVATED' ?
                                                            <Button onClick={() => setConfirmArchive(true)}
                                                                    title={postData?.status === 'DEACTIVATED' ? 'Return' : 'Archive'}
                                                                    iconRight={<ArchiveIcon/>}/>
                                                            : ''
                                                    }

                                                </div>
                                            </div>
                                            : ''
                                    }

                                    <h2 className="header4 body500">{postData?.title}</h2>
                                    <div
                                        className="PostDetails__content_post-right-description bodyM">{getTextWithParagraphs(postData?.description)}</div>

                                    {
                                        postData?.workType
                                            ? <span
                                                className='bodyXL'>
                                                <span className="body500">{t("main.Form.isRemote")}: </span>
                                                {postData?.workType}</span>
                                            : ''
                                    }

                                    {
                                        postData?.price
                                            ? <span
                                                className='bodyXL body500'>{postData?.price}$ {getPaymentType(t, postData)}</span>
                                            : ''
                                    }


                                    {
                                        postData?.otherInfo ? <div className="PostDetails__content_post-additionalInfo">
                                                {
                                                    Object.keys(additionalInfo).map((key) => {
                                                        if (key === 'type') {
                                                            return <div key={key} className="bodyM">
                                                                <span className='body500'>{t(`main.otherInfo.${key}`)}:</span>
                                                                <span> {t(`main.ToGoTypes.${additionalInfo[key]}`)}</span>
                                                            </div>
                                                        } else if (key === 'link') {
                                                            return <div key={key} className="bodyM">
                                                                <span className='body500'>{t(`main.otherInfo.${key}`)}: </span>
                                                                <a target={'_blank'} rel={'noreferrer'}
                                                                   className="primary_color"
                                                                   href={additionalInfo[key]}>{additionalInfo[key]}</a>
                                                            </div>
                                                        } else if (key === 'time') {
                                                            return <div key={key} className="bodyM">
                                                                <span className='body500'>{t(`main.otherInfo.${key}`)}:</span>
                                                                <span> {additionalInfo[key].split('T').join(' / ')}</span>
                                                            </div>
                                                        } else {
                                                            return <div key={key} className="bodyM">
                                                                <span className="body500">{t(`main.otherInfo.${key}`)}:</span>
                                                                <span> {additionalInfo[key]}</span>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                            : ''
                                    }

                                    <div className="bodyM body400 PostCard__job_bot-location">
                                        <ViewsCount viewsCount={postData?.views}/>
                                        {
                                            postData?.category?.categoryName !== 'questions' ? <>
                                                    <LocationIcon color={'var(--primary-color)'}/>
                                                    <GetLocationByIdSpan cardData={postData}/>
                                                </>
                                                : ''
                                        }
                                    </div>

                                    <span className="bodyM grey">{getADate(postData)}</span>


                                    {
                                        postData?.category?.categoryName !== 'sxodim' && postData?.category?.categoryName !== 'questions'
                                            ? <div className="PostDetails__content_post_contacts">


                                                <div className={`contactsRow`}
                                                     style={{height: `${showContacts ? returnHeightProfile(postData?.user, 95) : '0'}px`}}>


                                                    <div className="contact-row user_data">
                                                        <ProfileLogo logo={postData?.user?.avatar?.path}/>
                                                        <span className="contact-row_contact bodyL grey700 body500">
                                                {
                                                    postData?.user?.companyName || `${postData?.user?.firstName} ${postData?.user?.lastName}`
                                                }

                                            </span>
                                                    </div>


                                                    {
                                                        postData?.user?.phoneNumber
                                                        && <div className="contact-row">
                                                            <img src={Phone} alt=""/>
                                                            <span className="contact-row_contact bodyM grey700">
                                    {postData?.user?.phoneNumber}
                                            </span>
                                                        </div>
                                                    }

                                                    {
                                                        postData?.user?.whatsapp && <div className="contact-row">
                                                            <img src={Whats} alt=""/>
                                                            <span className="contact-row_contact bodyM grey700">
                                    {postData?.user?.whatsapp}
                                </span>
                                                        </div>
                                                    }

                                                    {
                                                        postData?.user?.telegram &&
                                                        <a href={postData?.user?.telegram} target={'_blank'} rel={'noreferrer'}
                                                           className="contact-row">
                                                            <img src={Telegram} alt=""/>
                                                            <span className="contact-row_contact bodyM grey700">
                                    @{postData?.user?.telegram.replace('https://t.me/', '')}
                                </span>
                                                        </a>
                                                    }
                                                </div>


                                                <Button onClick={() => setShowContacts(!showContacts)} active
                                                        title={t("main.actions.showContacts")}/>
                                            </div>
                                            : ''
                                    }

                                    <AdminButtons postData={postData}/>

                                </div>

                            </div>


                            {
                                (!user && samePosts?.length) ? <section className="MarketplaceMain__section">

                                    <div className="MarketplaceMain__CategoryLayout">
                                        <h2 className="header4">{t("main.navigation.addsLikeThis")}</h2>
                                    </div>


                                    <div className={getTypeOfCards('class')}>
                                        {samePosts?.length ? samePosts?.map((card, idx) => (
                                                <PostCard key={idx} cardData={card}/>
                                            ))
                                            : <NoContent/>
                                        }
                                    </div>

                                </section> : ''
                            }


                        </div>
                        : localLoader ? <LoaderComponent type/>
                            : <NoContent/>
                }
            </div>
        </main>
    );
};

export default PostDetails;