import React, {useEffect} from 'react';
import './AboutUs.scss';
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Bounce from 'react-reveal/Bounce';
import Erbol from './membersPictures/Erbol.jpg';
import Adilet from './membersPictures/Adilet.JPG';
import Esen from './membersPictures/Esen.JPG';
import Nurik from './membersPictures/Nuridin.jpg';
import Baigeldi from './membersPictures/Baigeldi.JPG';
import Cholpon from './membersPictures/Cholpon.jpg';
import Ermek from './membersPictures/Ermek.jpg';
import {useTranslation} from "react-i18next";

const AboutUs = () => {

    const {t} = useTranslation()
    useEffect(() => {
        const body = document.querySelector('body')
        body.classList.add('bodyLinear')
        return () => {
            body.classList.remove('bodyLinear')
        };
    }, []);

    const teamArray = [
        {
            fio: 'Nurekov Erbol',
            position: 'CEO / Web Developer',
            image: Erbol
        },

        {
            fio: 'Kadyrbekov Adilet',
            position: 'Backend Developer',
            image: Adilet
        },
        {
            fio: 'Omurchiev Esen',
            position: 'Backend Developer',
            image: Esen
        },
        {
            fio: 'Cholpon Chekirova',
            position: 'Product Manager',
            image: Cholpon
        },
        {
            fio: 'Nuridin Safaraliev',
            position: 'IOS Developer',
            image: Nurik
        },
        {
            fio: 'Baigeldi Akylbek Uulu',
            position: 'IOS Developer',
            image: Baigeldi
        },
        {
            fio: 'Kenjebek Kyzy Ermek',
            position: 'UX/UI Designer',
            image: Ermek
        }
    ]


    return (
        <main className="AboutUs">
            <div className="container">
                <div className="AboutUs__content">
                    <ButtonBack/>
                    <Bounce left>
                        <div className="AboutUs__content_MessageSection left">
                            <h2 className="header3">{t("main.AboutUs.hello")}</h2>
                            <span className="bodyL">
                              {t("main.AboutUs.message1")}
                             </span>
                        </div>
                    </Bounce>

                    <Bounce right>
                        <div className="AboutUs__content_MessageSection right">
                            <h2 className="header3">{t("main.AboutUs.aboutPlatform")}</h2>
                            <span className="bodyL">
                            <span className="body500">Qoomus</span>
                                {t("main.AboutUs.message11")}
                                <br/>
                            <span>{t("main.AboutUs.message12")}</span>
                           </span>
                        </div>
                    </Bounce>


                    <div className="AboutUs__content_Section">
                        <h2 className="header3">{t("main.AboutUs.ourTeam")}</h2>
                        <div className="AboutUs__content_Section_teamRow">
                            {
                                teamArray?.map((member, idx) => (
                                    <div key={idx} className="AboutUs__content_Section_teamRow-member">
                                        <img src={member?.image} alt=""/>
                                        <span className="bodyXL body500">{member?.fio}</span>
                                        <span className="bodyL grey">{member?.position}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {/*<div className="AboutUs__content_Section ">*/}
                    {/*    <h2 className="header3">История создания</h2>*/}
                    {/*    <span className="bodyL max700">*/}
                    {/*        История создания Qoomus началась с момента моего прибытия в штаты.*/}
                    {/*        По прилету в США по студенческой визе, первое время я не мог найти жилье,*/}
                    {/*        а после того как жилье, столкнулся с остальными проблемами как - транспортировка, подработка,*/}
                    {/*        поиск людей или студентов из моей страны. Занимаясь всеми этими проблемами у меня возникла идея - создание платформы*/}
                    {/*        для таких же как и я студентов или иммигрантов из Центральной Азии, платформы, которая сможет объеденить наше сообщество*/}
                    {/*        и поможет в решении всех этих проблем на первом этапе.*/}
                    {/*    </span>*/}
                    {/*    <img src={Qoomus} alt=""/>*/}
                    {/*</div>*/}

                </div>
            </div>
        </main>
    );
};

export default AboutUs;