import React from 'react';
import './NotFound.scss'
import {useTranslation} from "react-i18next";
import Animation404 from './404.gif';
import ButtonBack from "../../components/ButtonBack/ButtonBack";

const NotFound = () => {

    const {t} = useTranslation();

    return (
        <main className="NotFoundPage">

            <section className="NotFoundPage__side">
                <img className="NotFoundPage__image" src={Animation404} alt=""/>


                <h2>404 {t('main.NotFound')} :(</h2>

                <ButtonBack/>

            </section>


        </main>
    );
};

export default NotFound;