import React, {useEffect, useState} from 'react';
import './PostSomething.scss';
import {useTranslation} from "react-i18next";
import PostCard from "../../../../components/PostCard/PostCard";
import Button from "../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {getAdvertisementApi} from "../../../../api/postApi";
import NoContent from "../../../../components/NoContent/NoContent";
import {useRecoilState} from "recoil";
import {userCurrentCity} from "../../../../store/userDataStates";
import {categoriesAllList} from "../../../../store/postStates";

const PostSomething = () => {

    const {t} = useTranslation();
    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();
    const [location] = useRecoilState(userCurrentCity);
    const [categories] = useRecoilState(categoriesAllList);

    useEffect(() => {
        categories?.map((category) => {
            if (category?.categoryName === 'jobs') {
                getAdvertisementApi(0, 2, location?.id, category).then((res) => {
                    setJobs(res?.data?.content)
                })
            }
        })
    }, [categories])

    return (
        <main className="ExampleSection">
            <div className="container">

                <div className="ExampleSection__content">
                    <h2 className="ExampleSection__content_title header3">{t("main.examplesSection.secondTitle")}</h2>
                    <h4 className="ExampleSection__content_subTitle bodyM">{t("main.examplesSection.addDesk")}</h4>

                    <div className="ExampleSection__content_container">

                        <div className="ExampleSection__content_navigation">
                            <span onClick={() => {
                                navigate('/marketplace')
                                window.scrollTo({
                                    top: 0
                                })
                            }}>
                                <Button iconRight={<svg width="24" height="20" viewBox="0 0 24 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10H21M21 10L12.6875 2M21 10L12.6875 18" stroke="black"
                                          strokeWidth="3"/>
                                </svg>} type={'primary__button'} title={t("main.actions.moreAds")}/>
                            </span>
                        </div>

                        <div className="ExampleSection__content_cards">
                            {jobs?.length ? jobs?.map((card, idx) => (
                                    <PostCard key={idx} type={card?.category?.categoryName} cardData={card}/>
                                ))
                                : <NoContent/>
                            }
                        </div>
                    </div>

                </div>


            </div>
        </main>
    );
};

export default PostSomething;