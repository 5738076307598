import React from 'react';
import './LoaderComponent.scss';

const LoaderComponent = ({type}) => {
    return (
        <div className="LoaderComponent">
            <span className="loader">
                <div></div>
            </span>
        </div>
    );
};

export default LoaderComponent;