import React from 'react';
import './button.scss'

const Button = ({title, type, full, onClick, active, icon, iconRight, disabled, clx, buttonType}) => {
    return (
        <button type={buttonType} disabled={disabled} onClick={onClick}
                className={`bodyM button ${clx ? clx : ''} ${type ? type : ''} ${full ? 'fullWidth' : ''} ${active ? 'button__active' : ''}`}>
            {icon ? icon : ''}
            {title}
            {iconRight ? iconRight : ''}
        </button>
    );
};

export default Button;