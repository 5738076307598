import {atom} from 'recoil';

export const modalWindowCities = atom({
    key: 'modalWindowCities',
    default: false,
});

export const fullScreenLoaderState = atom({
    key: 'fullScreenLoader',
    default: false
})