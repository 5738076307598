import {Route, Routes} from "react-router-dom";
import Main from "./pages/Main/Main";
import {useRecoilState, useSetRecoilState} from "recoil";
import {allCitiesState, currentProfile, userCurrentCity} from "./store/userDataStates";
import React, {useEffect} from "react";
import {GetCityHook} from "./hooks/GetCityHook";
import ModalCitiesWindow from "./shared/ModalCitiesWindow/ModalCitiesWindow";
import {getAnalytics, logEvent} from "firebase/analytics";
import {appSDK} from "./firebase/firebaseSDK";
import NotFound from "./pages/404/NotFound";
import Layout from "./components/Layout/Layout";
import AboutUs from "./pages/AboutUs/AboutUs";
import 'swiper/css';
import 'swiper/css/navigation';
import Contacts from "./pages/Contacts/Contacts";
import AuthPage from "./pages/Auth/AuthPage";
import ChooseCategory from "./shared/ChooseCategory/ChooseCategory";
import Marketplace from "./pages/Marketplace/Marketplace";
import MarketplaceCategory from "./pages/Marketplace/MartketplaceCategory/MarketplaceCategory";
import PostDetails from "./pages/PostDetails/PostDetails";
import Profile from "./pages/Profile/Profile";
import {getProfileData, refreshAccessToken} from "./api/authApi";
import {toast} from "react-toastify";
import ProfileSettings from "./pages/Profile/ProfieSettings/ProfileSettings";
import MobileTabbar from "./shared/MobileTabbar/MobileTabbar";
import Development from "./pages/Development/Development";
import CreatePost from "./pages/CreatePost/CreatePost";
import {getAllCitiesApi} from "./api/locationApi";
import FullScreenLoader from "./shared/FullScreenLoader/FullScreenLoader";
import {fullScreenLoaderState} from "./store/modalStates";
import {categoriesAllList, paymentsAllList} from "./store/postStates";
import {getCategory, getPaymentTypes} from "./api/postApi";
import IsAuthorised from "./components/IsAuthorised/isAuthorised";
import ADMIN from "./pages/ADMIN/ADMIN";
import SupportUs from "./shared/SupportUs/SupportUs";

function App() {

    const analytics = getAnalytics(appSDK);
    logEvent(analytics, 'notification_received');
    const setProfile = useSetRecoilState(currentProfile);
    const setAllCities = useSetRecoilState(allCitiesState)
    const setUserCity = useSetRecoilState(userCurrentCity);
    const [fullScreenLoader] = useRecoilState(fullScreenLoaderState);
    const setPayments = useSetRecoilState(paymentsAllList);
    const [categories, setCategories] = useRecoilState(categoriesAllList);
    const setLoader = useSetRecoilState(fullScreenLoaderState);


    useEffect(() => {
        setLoader(true)
        getAllCitiesApi().then((cities) => {
            setAllCities(cities)
            if (!localStorage.getItem('location')) {
                GetCityHook(cities?.allList).then((res2) => {
                    setUserCity(res2)
                })
            } else {
                setUserCity(JSON.parse(localStorage.getItem('location')))
            }
        })

        getCategory().then((res) => {
            setCategories(res?.data)
        }).finally(() => {
            getPaymentTypes().then((res) => {
                setPayments(res?.data)
            }).finally(() => {
                if (localStorage.getItem('refreshToken')) {
                    refreshAccessToken().then((res) => {
                        getProfileData(setProfile, res?.data?.accessToken)
                    }).catch((err) => {
                        toast('Сессия истекла', {type: 'error', autoClose: 3000})
                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('refreshToken')
                    })
                }
                setLoader(false)
            })
        })

    }, [])

    return (
        <div className="App">

            <Routes>

                <Route path='' element={<Layout children={<Main/>}/>}/>
                <Route path={'/about'} element={<Layout children={<AboutUs/>}/>}/>
                <Route path={'/contacts'} element={<Layout children={<Contacts/>}/>}/>

                <Route path='/auth/*' element={
                    <AuthPage/>
                }/>


                <Route path={'/admin'} element={
                    <Layout
                        children={<ADMIN/>}
                    />
                }/>

                <Route path={'/marketplace'} element={
                    <Layout children={<Marketplace/>}/>
                }/>

                <Route path={'/development'} element={
                    <Development/>
                }/>

                <Route path={'/profile'} element={
                    <IsAuthorised children={
                        <Profile/>
                    }/>
                }/>

                <Route path={'/profile/:id'} element={
                    <IsAuthorised children={
                        <PostDetails user={true}/>
                    }/>
                }/>

                <Route path={'/profile/settings'} element={
                    <IsAuthorised children={
                        <ProfileSettings/>
                    }/>
                }/>

                <Route path={'/createPost'}
                       element={
                           <IsAuthorised children={
                               <CreatePost/>
                           }/>
                       }/>

                <Route
                    path={'/marketplace/details/:id'}
                    element={
                        <Layout children={<PostDetails/>}/>
                    }
                />

                {
                    categories?.map((category) => (
                        <Route key={category?.id} path={`/marketplace/${category?.categoryName}`} element={
                            <Layout children={<MarketplaceCategory/>}/>
                        }/>
                    ))
                }


                <Route path='/*' element={<NotFound/>}/>

            </Routes>

            {/*Modal windows*/}
            <MobileTabbar/>
            <ModalCitiesWindow/>
            <ChooseCategory/>
            <SupportUs/>
            {
                fullScreenLoader && <FullScreenLoader/>
            }

        </div>
    );
}

export default App;
