import React from 'react';
import {getLocationById} from "../../hooks/mainFunctions";
import {useRecoilState} from "recoil";
import {allCitiesState} from "../../store/userDataStates";

const GetLocationByIdSpan = ({cardData}) => {
    const [allCities] = useRecoilState(allCitiesState);
    return (
        <>
            {getLocationById(cardData, allCities?.allList)?.city}, {getLocationById(cardData, allCities?.allList)?.stateId}
        </>
    );
};

export default GetLocationByIdSpan;