import {instance} from "./instance";
import {toast} from "react-toastify";


export const registerUser = (body) => {
    return instance.post('auth/register-user', body).then((res) => {
        return res
    })
}

export const confirmCodeAxios = (email, code, setProfile) => {
    return instance.post(`auth/verify?code=${code}&email=${email}`).then((res) => {
        localStorage.setItem('accessToken', res?.data?.accessToken);
        localStorage.setItem('refreshToken', res?.data?.refreshToken);
        getProfileData(setProfile, res?.data?.accessToken)
        return res
    })
}

export const getProfileData = (setProfile, token) => {
    instance.get('user/profile', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then((res) => {
        setProfile(res?.data)
    })
}

export const refreshAccessToken = () => {
    return instance.post('auth/refresh-token', null, {
        headers: {
            'accept': '*/*',
            'Authorization': `Bearer ${localStorage.getItem('refreshToken')}`
        }
    }).then((res) => {
        localStorage.setItem('accessToken', res?.data?.accessToken)
        localStorage.setItem('refreshToken', res?.data?.refreshToken)
        return res
    })
}

export const signInWithPassword = (body, setProfile) => {
    return instance.post('auth/signin', body).then((res) => {
        localStorage.setItem('accessToken', res?.data?.accessToken);
        localStorage.setItem('refreshToken', res?.data?.refreshToken)
        getProfileData(setProfile, res?.data?.accessToken)
        return res
    })
}

export const changeProfileData = (body, setProfile) => {
    return instance.put(`user/${body?.id}`, body).then((res) => {
        setProfile(res?.data)
        toast('Изменено!', {type: 'success', autoClose: 3000})
        return res
    })
}

export const forgotPasswordSend = (email) => {
    return instance.post(`auth/rest-password?email=${email}`)
}

export const forgotPasswordConfirm = (email, code) => {
    return instance.post(`/auth/reset-password-verify?code=${code}&email=${email}`)
}

export const newPasswordSend = (newPassword, token) => {
    return instance.put(`/user/password`, {
        newPassword: newPassword
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}