import React, {useEffect, useState} from 'react';
import './MobileTabbar.scss';
import MarketplaceIcon from "../../assets/icons/MarketplaceIcon";
import {useMediaQuery} from "react-responsive";
import HomeIcon from "../../assets/icons/HomeIcon";
import {NavLink, useNavigate} from "react-router-dom";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import LampIcon from "../../assets/icons/LampIcon";
import AddPostButton from "../../assets/icons/AddPostButton";
import {currentProfile} from "../../store/userDataStates";
import {useRecoilState} from "recoil";
import {useTranslation} from "react-i18next";

const MobileTabbar = () => {

    const isMobile = useMediaQuery({maxWidth: 600});
    const [profile] = useRecoilState(currentProfile);
    const [hide, setHide] = useState();
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        const pages = ['createPost', 'profile', 'details', 'auth'];
        const containsSubstring = pages.some(item => window.location.href.includes(item));
        if (containsSubstring) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [navigate]);

    const scrollTop = () => {
        window.scrollTo({
            top: '0',
            behavior: 'smooth'
        })
    }

    return (isMobile &&
        <nav className={`MobileTabbar ${hide ? '' : 'MobileTabbarActive'}`}>

            <NavLink onDoubleClick={scrollTop} to={'/'}>
                <HomeIcon/>
                <span className="bodyXS">{t("main.mobileTabbar.main")}</span>
            </NavLink>

            <NavLink onDoubleClick={scrollTop} to={'/marketplace'}>
                <MarketplaceIcon/>
                <span className="bodyXS">{t("main.mobileTabbar.marketplace")}</span>
            </NavLink>

            {
                profile && <button onClick={() => {
                    navigate('/createPost')
                }} className="addButton">
                    <AddPostButton/>
                </button>
            }

            <NavLink onDoubleClick={scrollTop} to={'/profile'}>
                <ProfileIcon/>
                <span className="bodyXS">{t("main.mobileTabbar.profile")}</span>
            </NavLink>

            {
                profile?.role?.name === 'ROLE_ADMIN'
                    ? <NavLink onDoubleClick={scrollTop} to={'/admin'}>
                        <LampIcon/>
                        <span className="bodyXS">Admin</span>
                    </NavLink>
                    : <NavLink onDoubleClick={scrollTop} to={'/about'}>
                        <LampIcon/>
                        <span className="bodyXS">{t("main.navigation.about")}</span>
                    </NavLink>
            }

        </nav>

    );
};

export default MobileTabbar;