import React, {useState} from 'react';
import CustomInput from "../../components/CustomInput/CustomInput";
import {useTranslation} from "react-i18next";
import Button from "../../components/Button/Button";
import {forgotPasswordConfirm, forgotPasswordSend, newPasswordSend} from "../../api/authApi";
import {useSetRecoilState} from "recoil";
import {fullScreenLoaderState} from "../../store/modalStates";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ForgotPassword = () => {

    const {t} = useTranslation();
    const setLoader = useSetRecoilState(fullScreenLoaderState);
    const [steps, setSteps] = useState(0);
    const [token, setToken] = useState();
    const navigate = useNavigate();
    const [formSend, setFormSend] = useState({
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
    });

    const submitFirstStep = (e) => {
        e.preventDefault();
        setLoader(true)
        forgotPasswordSend(formSend?.email).then((res) => {
            setSteps(1)
            toast(t('main.Warnings.codeSent'), {type: 'info', autoClose: 3000});
        }).finally(() => {
            setLoader(false)
        })
    }

    const submitSecondStep = (e) => {
        e.preventDefault();
        setLoader(true)
        forgotPasswordConfirm(formSend?.email, formSend?.code).then((res) => {
            setSteps(2)
            setToken(res?.data?.accessToken)
            toast(t('main.Warnings.verified'), {type: 'success', autoClose: 3000});
        }).catch(() => {
            toast(t('main.Warnings.codeNotCorrect'), {type: 'error', autoClose: 3000})
        }).finally(() => {
            setLoader(false)
        })
    }

    const submitThirdStep = (e) => {
        e.preventDefault();
        setLoader(true);
        newPasswordSend(formSend?.password, token).then((res) => {
            toast(t("main.Form.pwChanged"), {type: 'success', autoClose: 3000});
            navigate('/auth/login')
        }).finally(() => {
            setLoader(false)
        })
    }


    const getAStepsContent = () => {
        switch (steps) {
            case 0:
                return <form onSubmit={submitFirstStep}>
                    <h2 className="header3">{t("main.Form.resetPw")}</h2>
                    <span className="bodyL">{t("main.Form.resetPwTitle")}</span>
                    <CustomInput value={formSend?.email}
                                 onChange={(e) => setFormSend({...formSend, email: e.target.value})} required
                                 width={'100%'}
                                 placeholder={t('main.Profile.email')} inputType={'email'}
                                 disableButton/>
                    <Button full title={t('main.actions.confirm')}/>
                </form>
            case 1:
                return <form onSubmit={submitSecondStep}>
                    <h2 className="header3">{t("main.Form.resetPw")}</h2>
                    <CustomInput value={formSend?.email} disabled
                                 onChange={(e) => setFormSend({...formSend, email: e.target.value})} required
                                 width={'100%'}
                                 placeholder={t('main.Profile.email')} inputType={'email'}
                                 disableButton/>
                    <span
                        className="bodyL">{t("main.Form.weSent")}</span>
                    <CustomInput value={formSend?.code}
                                 onChange={(e) => setFormSend({...formSend, code: e.target.value})} required
                                 width={'100%'}
                                 placeholder={t('main.Form.code')} inputType={'code'}
                                 disableButton/>
                    <Button full title={t('main.actions.confirm')}/>
                </form>
            default:
                return <form onSubmit={submitThirdStep}>
                    <h2 className="header3">{t("main.Form.resetPw")}</h2>
                    <CustomInput value={formSend?.password}
                                 onChange={(e) => setFormSend({...formSend, password: e.target.value})} required
                                 width={'100%'}
                                 placeholder={t('main.Profile.password')} inputType={'password'}
                                 disableButton/>
                    <CustomInput value={formSend?.confirmPassword}
                                 onChange={(e) => setFormSend({...formSend, confirmPassword: e.target.value})} required
                                 width={'100%'}
                                 placeholder={t('main.Profile.confirmPassword')} inputType={'password'}
                                 disableButton/>
                    <Button full title={t('main.actions.confirm')}/>
                </form>
        }
    }

    return (
        <main className={"ForgotPassword"}>
            {getAStepsContent()}
        </main>
    );
};

export default ForgotPassword;