const getDistance = (lat1, long1, lat2, long2) => {
    let R = 6371;
    let dLat = (lat2 - lat1) * (Math.PI / 180);
    let dLong = (long2 - long1) * (Math.PI / 180);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

export const GetCityHook = async (citiesDb) => {
    return new Promise((resolve, reject) => {
        return resolve(citiesDb[citiesDb.length - 1])
    });
}
