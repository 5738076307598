import React from 'react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import ChooseLocationInput, {themeSelectColor} from "../../../components/ChooseLocationInput/ChooseLocationInput";
import ChooseFilesInput from "../../../components/ChooseFilesInput/ChooseFilesInput";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {cdlType, truckMilesType, truckTypes} from "../../../hooks/stateTemplates";

const CreateTruck = ({postData, changePostKeyAndValue, changeAdditionalValue}) => {

    const {t} = useTranslation();

    return (
        <form className="CreatePost__content_form">

            <div className="row">
                <ChooseFilesInput changePostKeyAndValue={changePostKeyAndValue} postData={postData}/>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.title")}</span>
                    <CustomInput onChange={(e) => changePostKeyAndValue(e.target.value, 'title')}
                                 value={postData?.title}
                                 required placeholder={t("main.Form.title")} width={'100%'} disableButton/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.city")}</span>
                    <ChooseLocationInput postData={postData} required changeValue={changePostKeyAndValue}/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.otherInfo.truckType")}</span>

                    <Select required theme={themeSelectColor}
                            value={postData?.otherInfo?.truckType ? {type: postData?.otherInfo?.truckType} : ''}
                            defaultValue={postData?.otherInfo?.truckType}
                            onChange={(e) => changeAdditionalValue(e?.type, 'truckType')}
                            formatOptionLabel={(e) => t(`main.otherInfo.${e?.type}`)}
                            getOptionValue={(e) => e?.type}
                            options={truckTypes.map((element) => ({type: `${element}`}))}
                    />

                </label>
            </div>

            {
                postData?.otherInfo?.truckType === 'drivers'
                    ? <div className="row">
                        <label>
                            <span>{t("main.otherInfo.cdlType")}</span>

                            <Select required theme={themeSelectColor}
                                    value={postData?.otherInfo?.cdlType ? {type: postData?.otherInfo?.cdlType} : ''}
                                    defaultValue={postData?.otherInfo?.cdlType}
                                    onChange={(e) => changeAdditionalValue(e?.type, 'cdlType')}
                                    formatOptionLabel={(e) => e.type}
                                    getOptionValue={(e) => e?.type}
                                    options={cdlType.map((element) => ({type: `${element}`}))}
                            />

                        </label>
                    </div>
                    : ''
            }


            {
                postData?.otherInfo?.truckType === 'drivers' ?
                    <div className="row">
                        <label>
                            <span>
                                <a rel="noreferrer" style={{textDecoration: 'underline'}} className="primary_color" target={'_blank'}
                                   href="https://www.qoomus.net/marketplace/details/18">
                                        {t("main.otherInfo.truckMilesType")}
                                </a>
                            </span>
                            <Select required theme={themeSelectColor}
                                    value={postData?.otherInfo?.truckMilesType ? {type: postData?.otherInfo?.truckMilesType} : ''}
                                    defaultValue={postData?.otherInfo?.truckMilesType}
                                    onChange={(e) => changeAdditionalValue(e?.type, 'truckMilesType')}
                                    formatOptionLabel={(e) => e.type}
                                    getOptionValue={(e) => e?.type}
                                    options={truckMilesType.map((element) => ({type: `${element}`}))}
                            />
                        </label>
                    </div>
                    : ''
            }


            <div className="row">
                <label>
                    <span>{t("main.Form.description")}</span>
                    <CustomInput inputType={'textArea'}
                                 onChange={(e) => changePostKeyAndValue(e?.target.value, 'description')}
                                 value={postData?.description} required placeholder={t("main.Form.description")}
                                 width={'100%'}
                                 disableButton/>
                </label>
            </div>

        </form>
    );
};

export default CreateTruck;