import React, {useEffect, useState} from 'react';
import './CreatePost.scss';
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import {useTranslation} from "react-i18next";
import CreateCar from "./CreatePostForms/CreateCar";
import CreateApartment from "./CreatePostForms/CreateApartment";
import CreateOther from "./CreatePostForms/CreateOther";
import CreateJob from "./CreatePostForms/CreateJob";
import CreateBusiness from "./CreatePostForms/CreateBusiness";
import Button from "../../components/Button/Button";
import {postDataTemplate} from "../../hooks/stateTemplates";
import ChoosePromotion from "./ChoosePromotion/ChoosePromotion";
import {useRecoilState} from "recoil";
import {categoriesAllList, paymentsAllList} from "../../store/postStates";
import {toast} from "react-toastify";
import CreateSxodim from "./CreatePostForms/CreateSxodim";
import {currentProfile} from "../../store/userDataStates";
import {useNavigate} from "react-router-dom";
import CreateHomeFood from "./CreatePostForms/CreateHomeFood";
import CreateTruck from "./CreatePostForms/CreateTruck";
import CreateQuestion from "./CreatePostForms/CreateQuestion";
import CategoryCard from "../../components/CategoryCard/CategoryCard";

const CreatePost = () => {

    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [categories] = useRecoilState(categoriesAllList);
    const [paymentType] = useRecoilState(paymentsAllList);
    const [userData] = useRecoilState(currentProfile);
    const [postData, setPostData] = useState(postDataTemplate);
    const navigate = useNavigate();

    const changePostKeyAndValue = (val, key) => {
        setPostData({
            ...postData,
            [key]: val
        })
    }

    const changeAdditionalValue = (val, key) => {
        setPostData({
            ...postData,
            otherInfo: {
                ...postData.otherInfo,
                [key]: val
            }
        })
    }


    useEffect(() => {
        const body = document.querySelector('body')
        body.classList.add('bodyLinear');
        if (!userData?.telegram && !userData?.phoneNumber && !userData?.whatsapp) {
            navigate('/profile');
            toast(t("main.Warnings.fillContacts"), {type: 'warning', autoClose: 3000, position: 'top-center'})
        }
        return () => {
            body.classList.remove('bodyLinear')
        };
    }, [])


    const getCategoryForm = () => {
        return {
            'homeFoods': <CreateHomeFood changeAdditionalValue={changeAdditionalValue} postData={postData}
                                         changePostKeyAndValue={changePostKeyAndValue}
                                         paymentType={paymentType}/>,
            'sxodim': <CreateSxodim changeAdditionalValue={changeAdditionalValue} postData={postData}
                                    changePostKeyAndValue={changePostKeyAndValue}
                                    paymentType={paymentType}/>,
            'cars': <CreateCar postData={postData} changePostKeyAndValue={changePostKeyAndValue}
                               paymentType={paymentType}/>,
            'apartments': <CreateApartment postData={postData} changePostKeyAndValue={changePostKeyAndValue}
                                           paymentType={paymentType}/>,
            'business': <CreateBusiness postData={postData} changePostKeyAndValue={changePostKeyAndValue}
                                        paymentType={paymentType}/>,
            'jobs': <CreateJob postData={postData} changePostKeyAndValue={changePostKeyAndValue}
                               paymentType={paymentType}/>,
            'others': <CreateOther paymentType={paymentType} postData={postData}
                                   changePostKeyAndValue={changePostKeyAndValue}/>,
            'trucks': <CreateTruck changeAdditionalValue={changeAdditionalValue} postData={postData}
                                   changePostKeyAndValue={changePostKeyAndValue}
                                   paymentType={paymentType}/>,
            'questions': <CreateQuestion paymentType={paymentType} postData={postData}
                                         changePostKeyAndValue={changePostKeyAndValue}/>
        }[postData?.categoryId?.categoryName]
    }

    const changePage = () => {
        let form = document.querySelector('form');
        if (!form.reportValidity()) {
            toast(t("main.Warnings.fillAllInputs"), {type: 'info', autoClose: 3000, position: 'top-center'})
        } else {
            setPage(1)
        }
    }

    return (
        <main className="CreatePost">
            <div className="container">
                {
                    !page ? <div className="CreatePost__content">
                            <ButtonBack/>
                            <h2 className='CreatePost__content_title header4'>
                                {t('main.Form.postAd')}
                            </h2>

                            {
                                postData?.categoryId ? ''
                                    : <div className="CreatePost__content_categories">
                                        {
                                            categories && categories.length > 0 && categories?.map((category, idx) => (
                                                <CategoryCard key={idx} action={() => {
                                                    setPostData({
                                                        ...postDataTemplate,
                                                        categoryId: category
                                                    })
                                                }} cardData={category}/>
                                            ))
                                        }
                                    </div>
                            }

                            {/*<Select className='CreatePost__type' theme={themeSelectColor}*/}
                            {/*        value={postData?.categoryId}*/}
                            {/*        defaultValue={postData?.categoryId}*/}
                            {/*        onChange={async (e) => {*/}
                            {/*            setPostData({*/}
                            {/*                ...postDataTemplate,*/}
                            {/*                categoryId: e*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*        formatOptionLabel={(e) => t(`main.Categories.${e?.categoryName}`)}*/}
                            {/*        getOptionValue={(e) => e}*/}
                            {/*        options={categories}*/}
                            {/*/>*/}

                            {
                                postData?.categoryId ?
                                    <>
                                        {getCategoryForm()}
                                        <Button full clx={'buttonNext'} active title={t('main.actions.next')}
                                                onClick={() => changePage()}/>
                                    </>

                                    : ''
                            }

                        </div>
                        : <ChoosePromotion postData={postData} setPage={setPage}
                                           changePostKeyAndValue={changePostKeyAndValue}/>
                }
            </div>
        </main>
    );
};

export default CreatePost;