import React from 'react';
import './PostCard.scss';
import JobCard from "./JobCard/JobCard";
import ApartCarCard from "./ApartCarCard/ApartCarCard";

const PostCard = ({cardData}) => {

    const GetCardByType = () => {
        switch (cardData?.category?.categoryName) {
            case 'jobs':
                return <JobCard cardData={cardData}/>
            case 'questions':
                return <JobCard cardData={cardData}/>
            case 'cars':
                return <ApartCarCard cardData={cardData}/>
            case 'business':
                return <ApartCarCard cardData={cardData}/>
            case 'apartments':
                return <ApartCarCard cardData={cardData}/>
            case 'others':
                return <ApartCarCard cardData={cardData}/>
            default:
                return <ApartCarCard cardData={cardData}/>

        }

    }

    return GetCardByType();
};

export default PostCard;