import React from 'react';
import './NoContent.scss'
import {useTranslation} from "react-i18next";
import NoContentGif from './nocontent.gif'

const NoContent = () => {
    const {t} = useTranslation();
    return (
        <div className="NoContent">
          <div className="block">
              <img src={NoContentGif} alt="smile"/>
              <h2 className="bodyXL">{t("main.actions.notFound")}...</h2>
          </div>
        </div>
    );
};

export default NoContent;