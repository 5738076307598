import {instance} from "./instance";
import axios from "axios";

export const getAdminMainPageContent = async (setLoaders) => {
    setLoaders({
        others: true,
        jobs: true,
        apartments: true,
        cars: true,
        business: true
    })
    let mainPage = {
        others: [],
        jobs: [],
        apartments: [],
        cars: [],
        business: []
    }
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=1&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, apartments: res?.data?.content}
        setLoaders({
            others: true,
            jobs: true,
            apartments: false,
            cars: true,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=2&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, jobs: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: true,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=3&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, cars: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: false,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=4&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, business: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: false,
            business: false
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=5&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, others: res?.data?.content}
        setLoaders({
            others: false,
            jobs: false,
            apartments: false,
            cars: false,
            business: false
        })
    })
    return mainPage
}


export const approvePost = async (postData) => {
    const idChatObj = {
        1: '9',
        2: '15',
        3: '7',
        4: '17',
        5: '21',
        6: '19',
        7: null,
        8: '11',
        9: '114',
    };
    const token = '7207434243:AAEXFNbkdxV2fHvdJhSJyuN_B2spvRzSrnk';

    if (postData?.images[0]) {
        function dataURLtoBlob(dataurl) {
            let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }

        const getImage = () => {
            return new Promise((resolve, reject) => {
                let img = document.getElementsByClassName('image-gallery-image')[0] || document.getElementsByClassName('postImage')[0];
                if (!img) {
                    reject('Image not found');
                    return;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                img.setAttribute('crossorigin', 'anonymous');
                img.onload = () => {
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL('image/jpeg');
                    resolve(dataURLtoBlob(dataURL));
                };
                img.onerror = (err) => {
                    reject(err);
                };

                // In case the image is already loaded
                if (img.complete) {
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL('image/jpeg');
                    resolve(dataURLtoBlob(dataURL));
                }
            });
        };

        try {
            const imageBlob = await getImage();
            const formData = new FormData();
            formData.append('chat_id', '-1002206999627'); //806112049
            formData.append('message_thread_id', idChatObj[postData?.category.id]);
            formData.append('parse_mode', 'Markdown');
            formData.append('caption', `${postData?.title}  ${postData?.price ? `\n $${postData?.price} / ${postData?.priceType?.name}` : ''} \n#${postData?.location?.city} #${postData?.location?.stateId}  \n[Посмотреть на сайте](qoomus.net/marketplace/details/${postData?.id})`);
            formData.append('photo', imageBlob, 'image.jpg');

            await axios.post(`https://api.telegram.org/bot${token}/sendPhoto`, formData, {
                headers: {
                    "Content-Type": 'multipart/form-data'
                }
            });
        } catch (error) {
            console.error('Error loading or sending the image:', error);
        }
    } else {
        await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
            chat_id: '-1002206999627',
            message_thread_id: idChatObj[postData?.category.id],
            parse_mode: 'Markdown',
            link_preview_options: {
                is_disabled: true
            },
            text: `${postData?.title}  ${postData?.price ? `\n $${postData?.price} / ${postData?.priceType?.name}` : ''} \n#${postData?.location?.city} #${postData?.location?.stateId}  \n[Посмотреть на сайте](qoomus.net/marketplace/details/${postData?.id})
        `
        });
    }

    return await instance.put(`/adv/${postData?.id}`, {
        ...postData,
        status: 'APPROVED'
    });
};


export const rejectPost = async (postData) => {
    return await instance.put(`/adv/${postData?.id}`, {
        ...postData,
        status: 'REJECTED'
    })
}