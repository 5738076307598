import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDRLZeCQb-VUI_0muWlta7Ch5h5KmF9PCM",
    authDomain: "jumushnet.firebaseapp.com",
    projectId: "jumushnet",
    storageBucket: "jumushnet.appspot.com",
    messagingSenderId: "694529997740",
    appId: "1:694529997740:web:0aad5fc1ebc7c67ba65809",
    measurementId: "G-3Q6G0297T6"
};

export const appSDK = initializeApp(firebaseConfig);