import React from 'react';
import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";

const Layout = ({children}) => {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
};

export default Layout;