import React from 'react';
import PromotionType from "../../PromotionType/PromotionType";
import LocationIcon from "../../../assets/icons/locationIcon";
import {useTranslation} from "react-i18next";
import './JobCard.scss'
import {titleSlicer} from "../../../hooks/titleSlicer";
import {getADate, getPaymentType, navigateToPost} from "../../../hooks/mainFunctions";
import GetLocationByIdSpan from "../../GetLocationByIdSpan/GetLocationByIdSpan";
import StatusComponent from "../../StatusComponent/StatusComponent";
import {useNavigate} from "react-router-dom";
import ViewsCount from "../../ViewsCount/ViewsCount";

const JobCard = ({cardData}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div onClick={() => navigateToPost(cardData, navigate)} className={`PostCard PostCard__job`}>

            <div className="PostCard__job_top">

                <div className="PostCard__job_top-left">
                    {
                        cardData?.user?.avatar?.path && cardData?.price ?
                            <img src={cardData?.user?.avatar?.path} alt=""/>
                            : ''
                    }

                    <div className="PostCard__job_top-left_info">
                        <h2 className="bodyL body500">{cardData?.category?.categoryName !== 'questions' ? titleSlicer(cardData?.title) : cardData?.title}</h2>
                        {
                            cardData?.price ? <span className="bodyM body400">
                                    {
                                        cardData?.user?.companyName || `${cardData?.user?.firstName} ${cardData?.user?.lastName}`
                                    }
                                </span>
                                : cardData?.category?.categoryName !== 'questions' ?
                                    <span className="bodyM body400">
                                        {cardData?.description}
                                     </span>
                                    : ''
                        }
                    </div>

                </div>

                <div className="PostCard__job_top-right">


                    {
                        cardData?.status !== "UNDER_REVIEW" ?
                            <PromotionType contained={true} type={cardData?.promotionType?.name}/>
                            : <StatusComponent status={cardData?.status}/>
                    }

                    <span className="bodyM time">{getADate(cardData)}</span>
                </div>

            </div>

            {
                cardData?.price ?
                    <div className="PostCard__job_mid">
                        <span
                            className="bodyXL body500">{`$${cardData?.price} ${getPaymentType(t, cardData)}`}</span>
                    </div>
                    : ''
            }

            <div className="PostCard__job_bot">

                {
                    cardData?.category?.categoryName !== 'questions' ?
                        <div className="bodyM body400 PostCard__job_bot-location">
                            <LocationIcon color={'var(--primary-color)'}/>
                            <GetLocationByIdSpan cardData={cardData}/>
                        </div>
                        : ''
                }


                <ViewsCount viewsCount={cardData?.views}/>
            </div>

        </div>
    );
};

export default JobCard;