import React, {useEffect, useRef, useState} from 'react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {searchInputState} from "../../../store/searchState";
import PostCard from "../../../components/PostCard/PostCard";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import {getAdvertisementApi, getCategory} from "../../../api/postApi";
import {categoriesAllList} from "../../../store/postStates";
import NoContent from "../../../components/NoContent/NoContent";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import {getClassCards} from "../../../hooks/mainFunctions";
import './MarketplaceCategory.scss';
import {userCurrentCity} from "../../../store/userDataStates";
// import GoogleAdSense from "../../../components/GoogleAdSense/GoogleAdSense";

const MarketplaceCategory = () => {

    const {t} = useTranslation();
    const [searchState, setSearchState] = useRecoilState(searchInputState);
    const [lastSearch, setLastSearch] = useState();
    const [categories, setCategories] = useRecoilState(categoriesAllList);
    const [marketplaceList, setMarketplaceList] = useState({});
    const [loader, setLoader] = useState(false);
    const [location] = useRecoilState(userCurrentCity);
    const elementRef = useRef(null);
    const [page, setPage] = useState({
        count: 12,
        page: 0
    })

    const getLastPath = () => {
        const arr = window.location.pathname.split('/');
        return arr[arr.length - 1]
    }

    useEffect(() => {
        window.scroll({top: 0, behavior: 'smooth'})
        const body = document.querySelector('body');
        const modalWindow = document.querySelector('.modalWindowCities ');
        if (getLastPath().includes('sxodim')) {
            body.style.background = '#f1f1f1'
            body.style.setProperty('--grey-white-color', '#FFF');
            body.style.setProperty('--primary-color', 'rgb(233, 62, 44)');
            modalWindow.style.background = '#f1f1f1'
        }
        return () => {
            body.style.background = '#FFF'
            modalWindow.style.background = 'var(--secondary-color)'
            body.style.setProperty('--primary-color', '#11A27F');
            body.style.setProperty('--grey-white-color', '#F5F7FC');
        }
    }, []);

    const handleIntersection = async (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setPage({...page, page: page?.page + 1,});
                if (categories?.length) {
                    categories?.map((category) => {
                        if (category?.categoryName === getLastPath()) {
                            getAdvertisementApi(page?.page + 1, page?.count, location?.id, category, searchState).then((res) => {
                                setMarketplaceList({
                                    ...marketplaceList,
                                    last: res?.data?.last,
                                    content: [...marketplaceList?.content, ...res?.data?.content]
                                })
                            })
                        }
                    })
                }
            }
        });
    };


    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection);
        if (elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [marketplaceList]);

    const getCards = () => {
        setMarketplaceList({})
        setLoader(true)
        setPage({
            page: 0,
            count: 12
        })
        categories?.map((category) => {
            if (category?.categoryName === getLastPath()) {
                getAdvertisementApi(0, page?.count, location?.id, category, searchState).then((res) => {
                    setMarketplaceList(res?.data)
                    setLoader(false)
                }).catch((err) => {
                    setLoader(false)
                })
            }
            return category
        })
    }

    useEffect(() => {
        setLoader(true);
        if (!categories?.length) {
            getCategory().then((res) => {
                setCategories(res?.data)
                getCards(res?.data)
            })
        } else {
            getCards()
        }
    }, [location]);

    const getTypeOfCards = () => {
        return getClassCards(getLastPath())
    }

    const submitSearch = (e) => {
        if (lastSearch !== searchState) {
            getCards()
            setLastSearch(searchState)
        }
    }


    return (
        <main className="MarketplaceCategory">

            <div className="container">

                <div className="MarketplaceCategory__content">

                    <ButtonBack/>

                    <h2 className="header4">
                        <span>{t("main.navigation.found")} </span>
                        <span className="body600 primary_color"> {marketplaceList?.totalElements || 0} </span>
                        <span>{t("main.navigation.posts")}</span>
                    </h2>

                    <CustomInput value={searchState} clx={'MainSearchForm'}
                                 onChange={(e) => setSearchState(e.target.value)}
                                 onSearch={(e) => submitSearch(e)} width={'100%'}
                                 placeholder={`${t('main.actions.search')}...`}/>


                    <section className="MarketplaceMain__section">

                        <div className="MarketplaceMain__CategoryLayout">
                            <h2 className="header4">{t(`main.Categories.${getLastPath()}`)}</h2>
                        </div>


                        <div className={getTypeOfCards()}>
                            {loader
                                ? <LoaderComponent/>
                                : marketplaceList?.content?.length ? marketplaceList?.content?.map((card, idx) => (
                                        <PostCard key={idx} cardData={card}/>
                                    ))
                                    : <NoContent/>
                            }
                        </div>

                        {
                            marketplaceList?.content && !marketplaceList?.last
                                ? <div ref={elementRef}>
                                    <LoaderComponent/>
                                </div>
                                : ''
                        }

                    </section>

                    {/*google adsense*/}
                    {/*<section className="MarketplaceMain__section">*/}
                    {/*    <div className={getTypeOfCards('class')}>*/}
                    {/*        <GoogleAdSense/>*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                </div>

            </div>

        </main>
    );
};

export default MarketplaceCategory;