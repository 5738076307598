import React, {useEffect} from 'react';
import './ConfirmModal.scss';
import Button from "../../components/Button/Button";
import {useTranslation} from "react-i18next";

const ConfirmModal = ({title, confirmModal, setConfirmModal, action}) => {

    const {t} = useTranslation();
    useEffect(() => {
        let body = document.querySelector('body')
        if (confirmModal) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = 'auto'
        }
    }, [confirmModal])

    return (
        confirmModal && <div className="ConfirmModal">
            <div onClick={() => setConfirmModal(false)} className="ConfirmModal__blur"/>
            <div className="ConfirmModal__form">
                <h2>{title}</h2>
                <div className="buttonsRow">
                    <Button onClick={action} full title={t('main.actions.confirm')}/>
                    <Button full onClick={() => setConfirmModal(false)} title={t("main.actions.back")}/>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;