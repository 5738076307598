import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import "../src/assets/languages/i18n";
import './assets/fonts/Geologica/stylesheet.css'
import './fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <RecoilRoot>
            <App/>
            <ToastContainer/>
        </RecoilRoot>
    </BrowserRouter>
);
