import React from 'react';
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import './ButtonBack.scss'
import {useNavigate} from "react-router-dom";

const ButtonBack = ({customAction}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const goBackOrToHome = () => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            navigate('/')
        }
    };

    return (
        <Button clx={'ButtonBack'} onClick={customAction || goBackOrToHome} icon={
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.2899 11.9997L14.8299 8.4597C15.0162 8.27234 15.1207 8.01889 15.1207 7.7547C15.1207 7.49052 15.0162 7.23707 14.8299 7.0497C14.737 6.95598 14.6264 6.88158 14.5045 6.83081C14.3827 6.78004 14.252 6.75391 14.1199 6.75391C13.9879 6.75391 13.8572 6.78004 13.7354 6.83081C13.6135 6.88158 13.5029 6.95598 13.4099 7.0497L9.16994 11.2897C9.07622 11.3827 9.00182 11.4933 8.95105 11.6151C8.90028 11.737 8.87415 11.8677 8.87415 11.9997C8.87415 12.1317 8.90028 12.2624 8.95105 12.3843C9.00182 12.5061 9.07622 12.6167 9.16994 12.7097L13.4099 16.9997C13.5034 17.0924 13.6142 17.1657 13.736 17.2155C13.8579 17.2652 13.9883 17.2905 14.1199 17.2897C14.2516 17.2905 14.382 17.2652 14.5038 17.2155C14.6257 17.1657 14.7365 17.0924 14.8299 16.9997C15.0162 16.8123 15.1207 16.5589 15.1207 16.2947C15.1207 16.0305 15.0162 15.7771 14.8299 15.5897L11.2899 11.9997Z"
                    fill="black"/>
            </svg>
        } type={'primary__button'}
                title={t('main.actions.back')}/>
    );
};

export default ButtonBack;