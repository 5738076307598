import React from 'react';
import Select from 'react-select';
import {useRecoilState} from "recoil";
import {allCitiesState} from "../../store/userDataStates";


const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const formatGroupLabel = (data) => {
    return (
        <div style={groupStyles}>
            <span>{data?.city}, {data?.stateId}</span>
        </div>
    )
};

export const themeSelectColor = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: 'var(--grey-white-color)',
        primary: 'var(--primary-color)',
        neutral0: 'white'
    },
});

const ChooseLocationInput = ({postData, changeValue, required}) => {

    const [allCities] = useRecoilState(allCitiesState)

    return (
        <Select required={required} value={postData?.locationId}
                defaultValue={postData?.locationId}
                theme={themeSelectColor}
                getOptionLabel={(e) => e?.city}
                className={'react-select-container'} onChange={(e) => changeValue(e, 'locationId')}
                getOptionValue={(e) => e} options={allCities?.grouped?.filter((group) => group?.label !== 'All')}
                formatOptionLabel={formatGroupLabel}/>
    );
};

export default ChooseLocationInput;