import React from 'react';
import './PromotionType.scss';

const PromotionType = ({type, contained}) => {

    const getType = () => {
        switch (type) {
            case 'Partner':
                return 'Partner'
            case 'VIP':
                return 'Vip'
            case 'Premium':
                return 'Premium'
            default:
                return <></>
        }
    }

    return (
        type && <div className={`PromotionType body500 ${type}${contained ? 'Contained' : ''}`}>
            <span className='bodyM'>{getType()}</span>
        </div>
    );
};

export default PromotionType;