import React, {useEffect, useState} from 'react';
import './Profile.scss';
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import Settings from '../../assets/icons/settingsIcon.svg';
import ProfileLogo from "../../components/ProfileLogo/ProfileLogo";
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import PostCard from "../../components/PostCard/PostCard";
import {Swiper, SwiperSlide} from "swiper/react";
import Telegram from '../../assets/icons/Telegram.svg';
import Whats from '../../assets/icons/WhatsApp.svg';
import Phone from '../../assets/icons/phone.svg';
import {useTranslation} from "react-i18next";
import {getAdvByUser} from "../../api/postApi";
import EmptyContent from "../../components/EmptyContent/EmptyContent";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import {getClassCards, returnHeightProfile} from "../../hooks/mainFunctions";
import AddPostButton from "../../assets/icons/AddPostButton";
import {categoriesAllList} from "../../store/postStates";

const Profile = () => {

    const {t} = useTranslation();
    const [profileData] = useRecoilState(currentProfile);
    const [swiperRef, setSwiperRef] = useState(null);
    const navigate = useNavigate();
    const [userPosts, setUserPosts] = useState([]);
    const [loader, setLoader] = useState(false);
    const [categories] = useRecoilState(categoriesAllList);
    const [category, setCategory] = useSearchParams();

    useEffect(() => {
        setLoader(true)
        getAdvByUser().then((res) => {
            setUserPosts(res?.content)
            setLoader(false)
        })
    }, []);

    const getTypeOfCards = (type, category) => {
        if (type === 'class') {
            return getClassCards(category)
        }
    }

    const filteredArr = (cat) => {
        return userPosts?.filter((card) => card?.category?.categoryName === cat?.categoryName)
    }


    return (
        categories?.length && <main className="Profile">
            <div className="container">
                <div className="Profile__content">

                    <div className="Profile__content_line">
                        <ButtonBack customAction={() => navigate('/')}/>

                        <NavLink to={'settings'} className="Profile__content_line-settings">
                            <span className="bodyM">{t("main.Profile.profileSettings")}</span>
                            <img src={Settings} alt=""/>
                        </NavLink>

                    </div>

                    <div className="Profile__content_profile">
                        <div className="Profile__content_profile-userData">

                            <div className="userDataRow">
                                <ProfileLogo logo={profileData?.avatar?.path}/>
                                <h4 className="Profile__content_profile_name header4">
                                    {`${profileData?.firstName} ${profileData?.lastName}`}
                                </h4>

                                {
                                    profileData?.companyName &&
                                    <span className="Profile__content_profile_companyName bodyL grey700">
                                    {t("main.Profile.companyName")}: <span
                                        className="body500">{profileData?.companyName}</span>
                                </span>
                                }

                            </div>

                            <div className={` ${returnHeightProfile(profileData) ? 'contactsRow' : 'none'}`}>
                                <h2 className="bodyXL body500">{t("main.Profile.contactData")}:</h2>
                                {
                                    profileData?.phoneNumber
                                    && <div className="contact-row">
                                        <img src={Phone} alt=""/>
                                        <span className="contact-row_contact bodyL grey700">
                                    {profileData?.phoneNumber}
                                </span>
                                    </div>
                                }

                                {
                                    profileData?.whatsapp && <div className="contact-row">
                                        <img src={Whats} alt=""/>
                                        <span className="contact-row_contact bodyL grey700">
                                    {profileData?.whatsapp}
                                </span>
                                    </div>
                                }

                                {
                                    profileData?.telegram &&
                                    <a href={profileData?.telegram} target={'_blank'} rel={'noreferrer'}
                                       className="contact-row">
                                        <img src={Telegram} alt=""/>
                                        <span className="contact-row_contact bodyL grey700">
                                    @{profileData?.telegram.replace('https://t.me/', '')}
                                </span>
                                    </a>
                                }
                            </div>


                        </div>


                        <div className="buttonsRow">
                            <h2 className="bodyXL grey">{t("main.Profile.addsCount")}: {userPosts?.length}</h2>
                            <NavLink to={'/createPost'}>
                                <AddPostButton/>
                            </NavLink>
                        </div>

                        <div className={`Profile__content_categories ${category?.get('category')} `}>
                            {categories.map((cat, idx) => (
                                <div key={idx}
                                     onClick={() => {
                                         swiperRef?.slideTo(idx)
                                         setCategory({category: idx})
                                     }}
                                     id={`category${idx}`}
                                     className={`Profile__content_categories-category ${swiperRef?.activeIndex === idx ? 'active' : ''}`}>
                                    <span className="bodyM">{t(`main.Categories.${cat?.categoryName}`)}</span>
                                </div>
                            ))}
                        </div>


                        <div className={'cards_row_swiper'}>
                            {
                                loader ? <LoaderComponent/>
                                    : <Swiper
                                        autoHeight={true}
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        onSlideChange={(e) => {
                                            const element = document.getElementById(`category${e.activeIndex}`)
                                            const container = document.querySelector('.Profile__content_categories');
                                            if (element && container) {
                                                const elementCenter = element.offsetLeft + element.offsetWidth / 2;
                                                container.scrollLeft = elementCenter - container.offsetWidth / 2;
                                            }
                                            setCategory({category: e?.activeIndex})
                                        }} onSwiper={setSwiperRef} className="mySwiper">
                                        {
                                            categories.map((cat, idx) => (
                                                <SwiperSlide key={idx}>
                                                    <div className={getTypeOfCards('class', cat.categoryName)}>
                                                        {filteredArr(cat)?.length ? filteredArr(cat)?.map((card, idx) => (
                                                            <PostCard key={idx} cardData={card}/>
                                                        )) : <EmptyContent/>}
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                            }
                        </div>


                    </div>


                </div>
            </div>
        </main>
    );
};

export default Profile;