import React, {useState} from 'react';
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/Button/Button";
import {NavLink} from "react-router-dom";
import {signInWithPassword} from "../../api/authApi";
import {useSetRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import {toast} from "react-toastify";
import {fullScreenLoaderState} from "../../store/modalStates";
import {updateInstanceConfig} from "../../api/instance";
import {useTranslation} from "react-i18next";

const Login = () => {

    const setCurrentProfile = useSetRecoilState(currentProfile);
    const setLoader = useSetRecoilState(fullScreenLoaderState);
    const {t} = useTranslation();
    const [signInBody, setSignInBody] = useState({
        "username": "",
        "password": ""
    });

    const changeFormBody = (val, key) => {
        setSignInBody({
            ...signInBody,
            [key]: val
        })
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setLoader(true)
        signInWithPassword(signInBody, setCurrentProfile).then((res) => {
            setLoader(false);
            updateInstanceConfig();
            toast(t('main.Warnings.successLogin'), {type: 'success', autoClose: 3000});
        }).catch((err) => {
            setLoader(false);
            toast(t('main.Warnings.incorrectData'), {type: 'error', autoClose: 3000})
        });
    }

    return (
        <div className="AuthForm">
            <h2 className="header4">{t("main.navigation.login")}</h2>
            <form onSubmit={onSubmitHandler} className="Form">

                <CustomInput inputType={'email'} onChange={(e) => changeFormBody(e.target.value, 'username')}
                             disableButton={true}
                             placeholder={t("main.Profile.email")}/>

                <CustomInput inputType={'password'} onChange={(e) => changeFormBody(e.target.value, 'password')}
                             disableButton={true}
                             placeholder={t("main.Profile.password")}/>

                <NavLink to={'forgotPassword'}
                         className='forgotPassword bodyM'>{t("main.Profile.forgotPassword")}</NavLink>
                <Button title={t('main.actions.login')}/>

                {/*<Button icon={<img className="AuthForm__googleIcon" src={Google} alt=""/>}*/}
                {/*        title={'Войти через Google'}/>*/}
            </form>

            <NavLink to={'/auth/register'}>{t("main.Profile.noAccount")} <span
                className="primary_color">{t("main.Profile.createAccount")}</span></NavLink>

        </div>
    );
};

export default Login;