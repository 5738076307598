import React, {useEffect, useState} from 'react';
import Button from "../../components/Button/Button";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import PostCard from "../../components/PostCard/PostCard";
import NoContent from "../../components/NoContent/NoContent";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {getAdvertisementApi} from "../../api/postApi";
import {getClassCards} from "../../hooks/mainFunctions";
import {useRecoilValue} from "recoil";
import {userCurrentCity} from "../../store/userDataStates";

const MarketplaceSection = ({category, idx, admin}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [content, setContent] = useState(null);
    const location = useRecoilValue(userCurrentCity);

    useEffect(() => {
        setLoader(true)
        getAdvertisementApi(0, admin ? 99 : 4, admin ? null : location?.id, category, null, admin).then((res) => {
            setContent(res?.data?.content)
        }).finally(() => {
            setLoader(false)
        })
    }, [location])

    return (
        <section key={idx} className={`MarketplaceMain__section ${idx === 0 ? 'firstSection' : ''}`}>

            <div className="MarketplaceMain__CategoryLayout">
                <h2 className="header4">{t(`main.Categories.${category?.categoryName}`)}</h2>

                {
                    admin ? ''
                        : <Button onClick={() => {
                            navigate(category?.categoryName)
                        }} iconRight={
                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 10H21M21 10L12.6875 2M21 10L12.6875 18" stroke="black"
                                      strokeWidth="3"/>
                            </svg>
                        } type={'primary__button'} title={t('main.actions.moreAds')}/>
                }
            </div>


            <div className={getClassCards(category?.categoryName)}>
                {loader
                    ? <LoaderComponent/>
                    : content?.length ? content.map((card, idx) => (
                        <PostCard key={idx} type={'job'} cardData={card}/>
                    )) : <NoContent/>}
            </div>

        </section>
    );
};

export default MarketplaceSection;