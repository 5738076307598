import React from 'react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import ChooseLocationInput, {themeSelectColor} from "../../../components/ChooseLocationInput/ChooseLocationInput";
import ChooseFilesInput from "../../../components/ChooseFilesInput/ChooseFilesInput";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {sxodimTypes} from "../../../hooks/stateTemplates";

const CreateSxodim = ({postData, changePostKeyAndValue, changeAdditionalValue}) => {

    const {t} = useTranslation();

    return (
        <form className="CreatePost__content_form">

            <div className="row">
                <ChooseFilesInput changePostKeyAndValue={changePostKeyAndValue} postData={postData}/>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.title")}</span>
                    <CustomInput onChange={(e) => changePostKeyAndValue(e.target.value, 'title')}
                                 value={postData?.title}
                                 required placeholder={t("main.Form.title")} width={'100%'} disableButton/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.city")}</span>
                    <ChooseLocationInput postData={postData} required changeValue={changePostKeyAndValue}/>
                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.Form.advType")}</span>

                    <Select required theme={themeSelectColor}
                            value={postData?.otherInfo?.type ? {type: postData?.otherInfo?.type} : ''}
                            defaultValue={postData?.otherInfo?.type}
                            onChange={(e) => changeAdditionalValue(e?.type, 'type')}
                            formatOptionLabel={(e) => t(`main.ToGoTypes.${e.type}`)}
                            getOptionValue={(e) => e?.type}
                            options={sxodimTypes.map((element) => ({type: `${element}`}))}
                    />

                </label>
            </div>

            <div className="row">
                <label>
                    <span>{t("main.otherInfo.address")}</span>
                    <CustomInput onChange={(e) => changeAdditionalValue(e.target.value, 'address')}
                                 value={postData?.otherInfo?.address}
                                 required placeholder={'Адрес'} width={'100%'} disableButton/>
                </label>
            </div>

            {
                postData?.otherInfo?.type === 'event' ?
                    <div className="row">
                        <label>
                            <span>{t("main.otherInfo.time")}</span>
                            <CustomInput inputType={'datetime-local'}
                                         onChange={(e) => changeAdditionalValue(e.target.value, 'time')}
                                         value={postData?.otherInfo?.time}
                                         required placeholder={'Время'} width={'100%'} disableButton/>
                        </label>
                    </div>
                    : ''
            }

            <div className="row">
                <label>
                    <span>{t("main.otherInfo.link")}</span>
                    <CustomInput inputType={'url'} onChange={(e) => changeAdditionalValue(e.target.value, 'link')}
                                 value={postData?.otherInfo?.link}
                                 placeholder={'https://'} width={'100%'} disableButton/>
                </label>
            </div>


            <div className="row">
                <label>
                    <span>{t("main.Form.description")}</span>
                    <CustomInput inputType={'textArea'}
                                 onChange={(e) => changePostKeyAndValue(e?.target.value, 'description')}
                                 value={postData?.description} required placeholder={t("main.Form.description")} width={'100%'}
                                 disableButton/>
                </label>
            </div>

        </form>
    );
};

export default CreateSxodim;