import React from 'react';
import './AboutPlatform.scss';
import {useTranslation} from "react-i18next";
import CategoryCard from "../../../../components/CategoryCard/CategoryCard";
import {useRecoilState} from "recoil";
import {categoriesAllList} from "../../../../store/postStates";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";

const AboutPlatform = () => {

    const {t} = useTranslation();
    const [categories] = useRecoilState(categoriesAllList);

    return (
        <div className="AboutPlatform">
            <div className="container">
                <div className="AboutPlatform__content">
                    <h2 className="AboutPlatform__content_title header3">{t("main.examplesSection.mainTitle")}</h2>
                    <p className="AboutPlatform__content_subtitle bodyM">{t("main.examplesSection.mainSubtitle1")}</p>

                    <div className="AboutPlatform__content_categories">
                        {
                            categories?.length ? categories?.map((category, idx) => (
                                    <CategoryCard key={idx} cardData={category}/>
                                ))
                                : <LoaderComponent/>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AboutPlatform;