import React, {useEffect, useState} from 'react';
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/Button/Button";
import {NavLink} from "react-router-dom";
import {confirmCodeAxios, registerUser} from "../../api/authApi";
import {toast} from 'react-toastify';
import {useSetRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import {fullScreenLoaderState} from "../../store/modalStates";
import {updateInstanceConfig} from "../../api/instance";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../shared/ConfirmModal/ConfirmModal";

const Register = () => {

    const [steps, setSteps] = useState(0);
    const setProfile = useSetRecoilState(currentProfile);
    const [confirmCode, setConfirmCode] = useState('');
    const [checked, setChecked] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(' ');
    const setLoader = useSetRecoilState(fullScreenLoaderState);
    const {t} = useTranslation()
    const [timer, setTimer] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [registerBody, setRegisterBody] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "password": ""
    });

    useEffect(() => {
        const timerInterval = setInterval(() => {
            if (timer > 0) {
                setTimer(prevSeconds => prevSeconds - 1);
            }
        }, 1000);
        if (timer === 0) {
            clearInterval(timerInterval);
        }
        return () => clearInterval(timerInterval);
    }, [timer]);

    const submitRegister = (e) => {
        setTimer(59);
        setConfirmModal(false)
        if (e) {
            e.preventDefault();
        }

        setLoader(true);
        registerUser(registerBody).then((res) => {
            if (res?.status) {
                setLoader(false)
                toast(`${t('main.Warnings.codeSent')} - ${registerBody?.email}`, {type: 'success', autoClose: 3000});
                setSteps(1)
            }
        }).catch((err) => {
            setLoader(false)
            if (err.response.data.code === '400') {
                toast(t('main.Warnings.emailAlready'), {type: 'error', autoClose: 3000})
            }
        })
    }

    const changeFormBody = (val, key) => {
        setRegisterBody({
            ...registerBody,
            [key]: val
        })
    }

    const disabledButtons = () => {
        const all = document.querySelectorAll('inputs')
        if (checked) {
            all?.forEach((el) => {
                el.target.parentNode.classList.remove('InvalidInput')
            })

            return registerBody.password !== confirmPassword;
        } else {
            all?.forEach((el) => {
                el.target.parentNode.classList.add('InvalidInput');
            })
        }
        return true
    }

    const verifyFormHandler = (e) => {
        e.preventDefault();
        setLoader(true)
        confirmCodeAxios(registerBody.email, confirmCode, setProfile).then((res) => {
            updateInstanceConfig();
            toast(t('main.Warnings.verified'), {type: 'success', autoClose: 3000});
            setLoader(false)
        }).catch((err) => {
            if (err.response.data.code === '404') {
                toast(t('main.Warnings.codeNotCorrect'), {type: 'error', autoClose: 3000})
            }
            setLoader(false)
        });
    }

    return (
        !steps ? <div className="AuthForm">

                <ConfirmModal action={submitRegister} confirmModal={confirmModal} setConfirmModal={setConfirmModal}
                              title={<span>
                                  <span className="bodyXL">{t('main.actions.checkIsEmailCorrect')} - </span>
                                  <span className="body500 bodyXL">{registerBody?.email}</span>
                              </span>}/>

                <h2 className="header4">{t("main.Profile.register")}</h2>
                <form className="Form">

                    <CustomInput onChange={(e) => changeFormBody(e.target.value, 'firstName')}
                                 disableButton={true} placeholder={t("main.Profile.name")}/>

                    <CustomInput onChange={(e) => changeFormBody(e.target.value, 'lastName')} disableButton={true}
                                 placeholder={t("main.Profile.lastName")}/>

                    <CustomInput inputType={'email'} onChange={(e) => changeFormBody(e.target.value, 'email')}
                                 disableButton={true}
                                 placeholder={t("main.Profile.email")}/>

                    <CustomInput clx={'passwordInput'} inputType={'password'}
                                 onChange={(e) => changeFormBody(e.target.value, 'password')} disableButton={true}
                                 placeholder={t("main.Profile.password")}/>

                    <CustomInput clx={'passwordInput'} inputType={'password'}
                                 onChange={(e) => setConfirmPassword(e.target.value)} disableButton={true}
                                 placeholder={t("main.Profile.confirmPassword")}/>

                    <label className='forgotPassword bodyXS'>
                        <input onChange={(e) => setChecked(e.target.checked)} type="checkbox"/>
                        {t('main.actions.confirmAgreement')}
                    </label>


                    <Button buttonType={'button'} onClick={() => setConfirmModal(true)} disabled={disabledButtons()}
                            title={t("main.actions.register")}/>

                </form>
                <NavLink to={'/auth/login'}>{t("main.Profile.alreadyHaveAccount")} <span
                    className="primary_color">{t("main.navigation.login")}</span></NavLink>
            </div>
            : <div className="AuthForm">
                <h2 className="header4">{t("main.actions.confirmEmail")}</h2>
                <form onSubmit={verifyFormHandler} className="Form">

                    <CustomInput value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)}
                                 disableButton={true} placeholder={t('main.Form.code')}/>


                    <Button disabled={disabledButtons()}
                            title={t('main.actions.confirm')}/>


                </form>

                <span className="authConfirmTitle grey">
                    {t("main.Warnings.troublesWithCode")}
                    {t("main.actions.sentAgain")}
                    <span className="grey700 body500"> 0:{timer}</span>
                </span>

                <Button disabled={timer} onClick={() => submitRegister()}
                        title={t("main.actions.sentAgainAction")}></Button>
            </div>
    );
};

export default Register;