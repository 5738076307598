import {atom} from 'recoil';

export const searchInputState = atom({
    key: 'searchInpState',
    default: '',
});

export const chooseCategoryState = atom({
    key: 'chooseCategory',
    default: false,
});