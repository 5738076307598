import React from 'react';
import {useTranslation} from "react-i18next";
import './StatusComponent.scss'

const StatusComponent = ({status}) => {

    const {t} = useTranslation();

    return (
        <div className={`StatusComponent ${status}`}>
            <span>{t(`main.STATUSES.${status}`)}</span>
        </div>
    );
};

export default StatusComponent;