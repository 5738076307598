import {instance} from "./instance";
import {defaultLocation} from "../hooks/stateTemplates";

export const getAllCitiesApi = () => {
    return instance.get('/location', {
        headers: null
    }).then((cit) => {
        let res = {
            ...cit,
            data: [...cit?.data, defaultLocation]
        }
        const groupedObjects = res?.data.reduce((result, currentObject) => {
            const stateId = currentObject.stateId;
            if (!result[stateId]) {
                result[stateId] = {
                    label: stateId,
                    stateName: currentObject?.stateName,
                    options: [],
                };
            }
            result[stateId].options.push(currentObject);
            return result;
        }, {});

        return {
            grouped: Object.values(groupedObjects),
            allList: res?.data
        }
    })
}