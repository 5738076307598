import React from 'react';
import {Navigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";

const IsAuthorised = ({children}) => {

    const [profileData] = useRecoilState(currentProfile);
    if (!profileData) {
        return <Navigate to={'/auth/login'}/>
    }

    return (
        <>
            {children}
        </>
    );
};

export default IsAuthorised;