import React from 'react';
import OnDevelopmentGif from './onDevelopment.gif';
import {useTranslation} from "react-i18next";
import './Development.scss';
import ButtonBack from "../../components/ButtonBack/ButtonBack";

const Development = () => {

    const {t} = useTranslation();

    return (
        <main className="DevelopmentPage">
            <img src={OnDevelopmentGif} alt="on development page"/>
            <h2>{t("main.PageOnDev")}...</h2>
            <ButtonBack/>
        </main>
    );
};

export default Development;